import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

 

 

const styles = theme => ({
  table: {
    minWidth: 650,
  },
});

 


class TableData extends Component {

 

  render(){
  const { classes } = this.props;
  console.log(this.props)

 

  return (
    //<TableContainer component={Paper}>
      <Table className={classes.table} align="center" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email Id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.superAdmin.map(element => (
            <TableRow key={element.id}>
              <TableCell component="th" scope="row">
                {element.email}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    //</TableContainer>
  );
  }
}

 

export default withRouter(withStyles(styles)(TableData));