import React, { Component } from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Gobdashboard from './components/Gobdashboard'
import Main from './components/Main';
import AlertDashboard from './components/AlertDashboard';
import ScrollToTop from './components/ScrollTop';
import jwt from 'jwt-simple';
import Logout from './logout';
import ITbot from './components/ItAnalystBot';
import GobBot from './components/gobBot';
import Admin from './components/admin.js';
import axios from 'axios';



class PrivateRoute extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            isAuthenticated: false,
            admin: [],
            esb: [],
            superAdmin: [],
            data: false,
            error:false,
            akamiList: process.env.REACT_APP_AKAMI_LIST.split(","),
            admin: null,
            esb:null,
            superAdmin: null,
            goblist :null,
            AnnouncementUsers:null
        }
        //  this.params = new URLSearchParams(document.location.search);
        // this.saml_token = this.params.get("saml");
        this.parms = new URLSearchParams(document.location.search);
        this.saml_token = this.parms.get("saml");
        if(!this.saml_token && !localStorage.getItem("saml")){
            localStorage.setItem("pathname", this.props.location.pathname);
          }
        
                //this.webpage_callback = ((process.REACT_APP_ENVIRONMENT == "LOCAL") ? `${process.REACT_APP_ENVIRONMENT_URL}/#/` : (process.REACT_APP_ENVIRONMENT +"/#"))+localStorage.getItem("pathname")
                this.webpage_callback = "https://acc.inside.athena.philips.com/#"+localStorage.getItem("pathname")
                
             // }else{
              //  this.webpage_callback = "https://acc.inside.athena.philips.com/#/"
             // }
        // }catch(error){
        //   console.log(error)
        // }
        // try{
        //     console.log(document.cookie);
        // }catch(error){
        //     console.log(error);
        // }
        // if (document.cookie) {
        //     this.sessionID = document.cookie.replace(/(?:(?:^|.*;\s*)idtoken\s*\=\s*([^;]*).*$)|^.*$/, "$1")
        //     console.log("session id frm cookie", this.sessionID)
        // }
        this.sessionValid = false
        if (this.saml_token) {
            localStorage.setItem("saml", this.saml_token)
        } else {
            this.stored_token = localStorage.getItem("saml")
           // document.cookie = "id_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/"
            if (this.sessionID) {
                console.log("sessionid captured")
                let token = jwt.decode(this.stored_token, process.env.REACT_APP_LOGIN_JWT_SECRET, true, process.env.REACT_APP_LOGIN_JWT_SECRET_ALGORITHM)
                let sessionToken = jwt.decode(this.sessionID, process.env.REACT_APP_LOGIN_JWT_SECRET, true, process.env.REACT_APP_LOGIN_JWT_SECRET_ALGORITHM)
                console.log("sessions", sessionToken.sessionIndex, token.sessionIndex)
                this.sessionValid = sessionToken.sessionIndex == token.sessionIndex
            }
            if (this.stored_token) {
                localStorage.removeItem("saml")
                try{
                this.userProfile = jwt.decode(this.stored_token, "AvaamoChatBot20", false, "HS256");
                console.log(this.userProfile)
                }catch(error){
                    console.log(error)
                    this.stored_token = false;
                }
               // localStorage.removeItem("path")

            }
        }

       
    }

    componentDidMount() {
        console.log("----------------------this in cdm")
        console.log("-------------cdm end----------------------")
        if (this.stored_token) {
            this.getData()
        } else {
            console.log("-----------fetch failed -----------")
        }

    }



    getData() {
        console.log("this in getDtata------------------")

        axios.get(process.env.REACT_APP_ACCESS_DATA_API,{auth: {
             username: process.env.REACT_APP_ACCESS_DATA_API_AUTH_USER,
             password: process.env.REACT_APP_ACCESS_DATA_API_AUTH_PASS
        }}).then(response => {
         //   console.log(response)

           // console.log(response.data.dashboardUsers)
            this.setState({
                admin: response.data.dashboardUsers,
                esb: response.data.esbUsers,
                superAdmin: response.data.uamAdmin,
                goblist :response.data.GOBdashboardUsers,
                AnnouncementUsers:response.data.AnnouncementUsers,
                data: true
            });

        }).catch(error=> {
            console.log(error)
             this.setState({
                data: true,
                error:true
            });
        });


    }


    render() {
        const { component: Component, ...rest } = this.props;
        let akami_access = false
        let dashboard_access = false
        let esb_access = false
        let admin_access = false
        let gob_access = false
        let firstName, lastName, codeID, emailID = ""
        let saml = ""
        let admin_data = this.state.data 
        let alerts_access = false;

        if (this.stored_token) {
            

            console.log("---------session valid------", this.sessionValid)
            saml = this.stored_token
            let user_profile = this.userProfile

            firstName = user_profile.firstName
            lastName = user_profile.lastName
            codeID = user_profile.codeID
            emailID = user_profile.emailID
            let useremail = emailID ? emailID.toLowerCase() : undefined ;

            if (this.state.data) {
                let dadmin = this.state.admin
                let esbusers = this.state.esb
                let uamadmin = this.state.superAdmin
                let akamiList = this.state.akamiList
                let gobusers = this.state.goblist
                let AnnouncementUsers = this.state.AnnouncementUsers

               // console.log(useremail)
               try{
                let dashAdmin = dadmin.includes(useremail)
                let esbuser = esbusers.includes(useremail)
               // console.log(dashAdmin, esbuser)
                let suAdmin = uamadmin.includes(useremail)
                akami_access = akamiList.includes(useremail)
                gob_access = gobusers.includes(useremail)
                alerts_access = AnnouncementUsers.includes(useremail)
               // console.log(dashAdmin) AnnouncementUsers
                if (dashAdmin) {
                    dashboard_access = true
                }
                if (esbuser) {
                    esb_access = true
                }
                if (suAdmin) {
                    admin_access = true
                }
            }catch(error)
                    {
                       console.log(error) 
                    }            }




        }

       /// console.log(this.state)
        //console.log(this.state.superAdmin)





        //console.log("access control", dashboard_access, esb_access, admin_access, akami_access)
console.log("this.webpage_callback--->", this.webpage_callback);
        return (
            <Route
                {...rest}
                render={props =>
                    this.stored_token ? (
                        <Component {...props} saml={saml} firstName={firstName} lastName={lastName} codeID={codeID} emailID={emailID} dashboard_access={dashboard_access} admin_access={admin_access} esb_access={esb_access} akami_access={akami_access} gob_access={gob_access} alerts_access= {alerts_access} admin_data={admin_data}/>
                    ) : this.saml_token ? (
                        <Route
                            path="/"
                            component={() => {
                                //window.location.href = this.webpage_callback.includes('null') ? ((process.REACT_APP_ENVIRONMENT == "LOCAL") ? process.REACT_APP_ENVIRONMENT_URL+"/" : process.REACT_APP_ENVIRONMENT +"/#/") : this.webpage_callback ;
                                window.location.href = this.webpage_callback.includes('null') ? 'https://acc.inside.athena.philips.com/#/' : this.webpage_callback ;
                                return null
                            } }
                            />
                    ) :
                            (
                                <Route
                                    path="/"
                                    component={() => {
                                        window.location.href = "https://acc.inside.athena.philips.com/login";
                                        return null
                                    } }
                                    />
                            )
                    // )
                }
                />
        )
    }
}

export default props => (
    <HashRouter>
        <ScrollToTop>
            <Switch>
                <PrivateRoute exact path='/' component={Main} />
                <PrivateRoute exact path='/dashboard' component={Dashboard} />
                <PrivateRoute exact path='/gobdashboard' component={Gobdashboard} />
                <PrivateRoute exact path='/ItAnalystBot' component={ITbot} />
                <PrivateRoute exact path='/gob' component={GobBot} />
                <PrivateRoute exact path='/admin' component={Admin} />
                <PrivateRoute exact path='/alerts' component={AlertDashboard} />
                <Route exact path='/logout' component={Logout} />
            </Switch>
        </ScrollToTop>
    </HashRouter>
)