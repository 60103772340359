import React, { Component } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import jwt from 'jwt-simple';



const logo = require('../images/Philips_logo_neu.gif');

const drawerWidth = 300;

const styles = theme => ({
    root: {
        display: 'flex',
        textDecoration: "none"
    },
    actionButton: {
        fontFamily: "Poppins-Bold",
        fontSize: "14px",

    },
    topbarContent: {
        marginLeft: "auto",
        alignItems: 'none'
    },
    actionIcon: {

    },
    navbar: {
        background: '#fff'
    },
    anchor: {
        textDecoration: "none",
        color: '#01579B'
    },
    orange: {
        color: '#fff',
        backgroundColor: '#0277BD',

    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'left',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'space-between'
    },
    userDetails: {
        fontFamily: 'Impact',
        paddingLeft: '10px'

    },
    drawerContent: {
        paddingTop: '5px',
        display: 'flex',
        flexWrap: 'wrap'


    },
    feedback: {
        textTransform: "none",
        fontSize: "12px",
        color: "#FFFFFF",
        fontWeight: "bold",
        backgroundColor: "#1565c0",
        marginLeft: "28px"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        backgroundColor: '#0B31CD'
    },
});


class PersistentDrawerLeft extends Component {


    constructor(props) {
        super(props);
        this.state = {
            open: false,
            firstName: '',
            lastName: '',
            email: '',
            codeID: ''

        }
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.switched = this.switched.bind(this);
        this.switchValuehome = this.switchValuehome.bind(this)
    }

    handleDrawerOpen() {

        this.setState({ open: true })
        if (this.props.firstName) {
            let firstName = this.props.firstName
            let lastName = this.props.lastName
            let email = this.props.emailID
            let codeID = this.props.codeID
            this.setState({ firstName: firstName, lastName: lastName, email: email, codeID: codeID, gotInfo: true })
        }


    };

    handleDrawerClose() {
        this.setState({ open: false })
    };

    switched() {
        if (localStorage.getItem('switch') === 'true') {
            localStorage.setItem('switch', false)

        }
        else {
            localStorage.setItem('switch', true)
        }

    }

    switchValue() {
        if (localStorage.getItem('switch') === 'true') {
            return true;

        }
        else {
            return false;
        }


    }

    switchValuehome() {
        localStorage.setItem("switch", false)
    }




    render() {
        const {classes} = this.props;


        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                    >
                    <Toolbar className={classes.navbar}>
                        <IconButton
                            color="black"
                            aria-label="open drawer"
                            onClick={this.handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, this.state.open && classes.hide)}
                            >
                            <MenuIcon />
                        </IconButton>

                        <img width={70} src={logo} alt="Philips Logo" />

                        { this.props.location.pathname != "/gobdashboard" && this.props.location.pathname != "/gob" ? (<Button
                            disableRipple
                            className={classes.feedback}
                            href="https://share.philips.com/sites/STS20140626161501/SitePages/Feedback.aspx?q3=ITBot"
                            target="_blank"
                            >
                            Feedback for Philips IT
                           </Button>):(<div></div>)}
                       { this.props.location.pathname != "/gobdashboard" && this.props.location.pathname != "/gob" ? (
                             <div className={classes.topbarContent}>
                            <Button href="#/" className={classes.actionButton} onClick={this.switchValuehome}>Home<HomeIcon fontSize="small" color="disabled" style={{ marginLeft: '2px', marginBottom: '3px' }} /></Button>

                        </div>):(<div></div>)}

                       
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={this.state.open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    >

                    <div className={classes.drawerHeader}>
                        <div className={classes.drawerContent}><Avatar className={classes.orange}>{this.state.firstName[0] + this.state.lastName[0]}</Avatar>
                            <Typography className={classes.userDetails}>
                                <Typography>{this.state.firstName + " " + this.state.lastName}</Typography>
                                <Typography style={{ fontSize: '12px', fontFamily: "" }}>Code1 ID : {this.state.codeID}</Typography>
                                <Typography style={{ fontSize: '10px' }}>{this.state.email}</Typography>
                            </Typography>
                        </div>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>


                    <Divider />

                    <List>

                        {/*
          {[{'text':'IT Analyst Bot','url':'#/ItAnalystBot'},{'text':'Analytics','url':'#/dashboard'},].map((obj, index) => (
            <ListItem button key={obj.text}>
              <a href={obj.url} className={classes.anchor } style={{ textDecoration: 'none' }}><ListItemText primary={obj.text} /></a>
            </ListItem>
          ))}
        
          */}


                        {
                            this.props.esb_access ? (this.switchValue() ? (<a href='#/' style={{ textDecoration: 'none' }} onClick={this.switched}>
                                <ListItem button >
                                    <ListItemText primary='Athena' color='secondary' style={{ color: '#00008B' }} />
                                </ListItem>
                            </a>) : (<a href='#/ItAnalystBot' style={{ textDecoration: 'none' }} onClick={this.switched}>
                                <ListItem button >
                                    <ListItemText primary='IT Analyst Bot' color='secondary' style={{ color: '#00008B' }} />
                                </ListItem>
                            </a>)) : (<div></div>)
                        }



                        {
                            this.props.dashboard_access ? (<a href='#/dashboard' style={{ textDecoration: 'none' }} onClick={this.switchValuehome}>
                                <ListItem button>
                                    <ListItemText primary='Analytics' color='secondary' style={{ color: '#00008B' }} />
                                </ListItem>
                            </a>) : (<div></div>)
                        }
                         {
                            this.props.gob_access ? (<a href='#/gobdashboard' style={{ textDecoration: 'none' }} onClick={this.switchValuehome}>
                                <ListItem button>
                                <ListItemText primary='GOB Dashboard' color='secondary' style={{ color: '#00008B' }} />
                                </ListItem>

                            </a>) : (<div></div>)
                            }

                        {
                            this.props.admin_access ? (<a href='#/admin' style={{ textDecoration: 'none' }} onClick={this.switchValuehome}>
                                <ListItem button>
                                    <ListItemText primary='Settings' color='secondary' style={{ color: '#00008B' }} />
                                </ListItem>
                            </a>) : (<div></div>)
                        }
                         {
              this.props.alerts_access ? (<a href='#/alerts' style={{ textDecoration: 'none' }} onClick={this.switchValuehome}>
                <ListItem button>
                  <ListItemText primary='Alerts' color='secondary' style={{ color: '#00008B' }} />
                </ListItem>
              </a>) : (<div></div>)
            }

                    </List>
                    {this.props.dashboard_access || this.props.esb_access || this.props.admin_access ? (<Divider />) : (<div></div>)}
                     { this.props.location.pathname != "/gobdashboard" && this.props.location.pathname != "/gob" ? (<List>
                        <a href='https://www.inside.athena.philips.com/smart_service_desk/' target="_blank" download="UserManual.pdf" style={{ textDecoration: 'none' }}>
                            <ListItem button>
                                <ListItemText primary='User Manual' color='secondary' style={{ color: '#00008B' }} />
                            </ListItem>
                        </a>
                    </List>):(<div></div>)} 
                    { this.props.location.pathname != "/gobdashboard" && this.props.location.pathname != "/gob"  ? (<Divider />) :(<div></div>) }
                    <List style={{ marginTop: 'inherit' }}>
                        {[{ 'text': 'Logout', 'url': 'https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0' }].map((obj, index) => (
                            <a key={obj.text} href={obj.url} style={{ textDecoration: 'none' }}>
                                <ListItem button href={obj.url}>
                                    <a href={obj.url} style={{ textDecoration: 'none', display: 'flex' }} className={classes.anchor} >
                                        <ListItemText primary={obj.text} style={{ color: '#00008B' }} />
                                        <ExitToAppIcon color="disabled" style={{ paddingLeft: '2px', paddingTop: '2px' }} /></a>
                                </ListItem>
                            </a>
                        ))}
                    </List>
                </Drawer>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: this.state.open,
                    })}
                    >

                </main>
            </div>
        );
    }
}


// export default withRouter(withStyles(useStyles)(PersistentDrawerLeft))

export default withRouter(withStyles(styles)(PersistentDrawerLeft));