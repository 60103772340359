import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const backgroundShape = require('./components/cssfiles/images/bg02-main2.jpg');
const styles = theme => ({
  root: {
    backgroundImage:`url(${backgroundShape})`,
    backgroundSize: 'cover',
    opacity:".90",
    overflow: 'hidden',
    backgroundposition: 'center center',
    backgroundrepeat: 'no-repeat',
    backgroundsize: 'contain',
    width: '100vw',
    height: '100vh'
  
    //backgroundColor: theme.palette.grey['250'],
    // background: "#83a4d4", /* fallback for old browsers */
    // background: '#373B44', /* fallback for old browsers */
    // background: '-webkit-linear-gradient(to right, #4286f4, #373B44)', /* Chrome 10-25, Safari 5.1-6 */
    // background: 'linear-gradient(to right, #4286f4, #373B44)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    
    //background: `url(${backgroundShape}) no-repeat`,

    // backgroundPosition: '0 400px',
    // paddingBottom: 50,
    // height:'100vh'
    // //border:"dashed"
  },
  paper: {
    padding: theme.spacing(9,5),
    textAlign: 'center',
    color: theme.palette.text.primary
  },
  grid: {
    marginTop: 150,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% 20px)'
    }
  }


});

class Logout extends Component{

    render(){
        const { classes } = this.props;
        return(
       <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
        <Grid container alignItems="center" justify="center" container className={classes.grid} >
              <Grid item xs={8} md={4} justify="center">
                <Paper className={classes.paper} elevation="20">
                    <Typography variant="h5" component="h6" color='secondary' /*className={classes.info}*/ style={{"font-family": "Poppins-Bold"}}>
                                   You have signed out successfully
                    </Typography>
                  </Paper>
                </Grid>
        </Grid>
        </div>
        </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(Logout));