import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Topbar from './Topbar';
import Grid from '@material-ui/core/Grid';
import { FormGroup } from '@material-ui/core';
import axios from 'axios';
import SimpleExpansionPanel from './toogle.js'
 

import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.grey['250'],
    // background: "#83a4d4", /* fallback for old browsers */
    background: "-webkit-linear-gradient(to right, #83a4d4, #b6fbff)", /* Chrome 10-25, Safari 5.1-6 */
    background: "linear-gradient(to left, #62e83430, #97fa9e7d)",
    overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 50,
    width: "100%",
    height:'100%',
    paddingTop: 90,
    paddingBottom: 216,
    flex:'inherit'
  },

  grid: {
    width: 1200,
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  table: {
    minWidth: 650,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    marginTop: 'auto',
    marginBottom:"auto"
  },
});

class Adminn extends Component {

  constructor(props){

    super(props);
    this.state={
    learnMoredialog: false,
    emailID:"",
    dashboardAdmin:[]
    }
  }


  

  


 
  render() {
      
    const { classes } = this.props;
    console.log(this.state)

    if(!this.props.admin_access){
        return(
            <Grid></Grid>
        )
    }
    else{
    return (
      <React.Fragment>
        <CssBaseline />

        <Topbar alerts_access = {this.props.alerts_access}  akami_access={this.props.akami_access} firstName={this.props.firstName} lastName={this.props.lastName} codeID={this.props.codeID} emailID={this.props.emailID} dashboard_access={this.props.dashboard_access} admin_access={this.props.admin_access} esb_access={this.props.esb_access}/>
        <div className={classes.root}>
            <Grid container justify="center" align='center'>
                  <Grid container justify="center" align='center' md={10} >
                        <SimpleExpansionPanel  />
                  </Grid>
             </Grid>
        </div>
  
      </React.Fragment>
    )
    }
  }
}

export default withRouter(withStyles(styles)(Adminn));