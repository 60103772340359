import React, { Component } from "react";
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import'bootstrap/dist/css/bootstrap.min.css';
import $ from'jquery';
import'bootstrap/dist/js/bootstrap.bundle.min';


const styles = theme => ({
  announcement:{
    padding: theme.spacing(1,2),
    fontSize:"12px",
    textAlign:"justify",
    fontFamily:"Lato,sans-serif",
    color:"#000000"
  },
  
  nested:{
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  }
});

class Carousel extends Component {

  constructor(props){
    super(props);
    this.state={
    }
  }


  render() {
    const { classes } = this.props;
    return (
        <div  id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" style={{paddingTop:"30px"}} data-interval="10000">
        
                    <div className="carousel-inner">
                    <div className="carousel-item active">
            <Grid xs={12} md={12} style={{}}>
              <Typography
                variant="body1"
                align="left"
                className={classes.announcement}
                >
                <b>Alert </b>
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.announcement}
                >
                Dear User,
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.announcement}
                >
                This is the QA/Test environment of Athena Chatbot. If you are a bussiness user, please <Link color="error" href="https://www.inside.athena.philips.com/" style={{ fontFamily: "Lato,sans-serif" }}>click here</Link> to access the production environment of Athena Chatbot.

              </Typography>
            </Grid>
          </div>
          <div className="carousel-item">
            <Grid xs={12} md={12} style={{}}>
              <Typography
                variant="body1"
                align="left"
                className={classes.announcement}
                >
                <b>Alert </b>
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.announcement}
                >
                Dear User,
              </Typography>
              <Typography
                variant="body1"
                align="left"
                className={classes.announcement}
                >
                This is the QA/Test environment of Athena Chatbot. If you are a bussiness user, please <Link color="error" href="https://www.inside.athena.philips.com/" style={{ fontFamily: "Lato,sans-serif" }}>click here</Link> to access the production environment of Athena Chatbot.

              </Typography>
            </Grid>
          </div>

                     
                   
                             
                  </div>
                  </div>
           
      
    )
  }
}

export default withRouter(withStyles(styles)(Carousel));