import React, { Component, createRef } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Topbar from './Topbar';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
//const backgroundShape = require('./cssfiles/images/bg03.jpg');
import "./cssfiles/css/main.css";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Plot from 'react-plotly.js';
import Download from './download';
import Button from '@material-ui/core/Button';


const styles = theme => ({
    root: {
        flexGrow: 1,
        //backgroundColor: theme.palette.grey['250'],
        // background: "#83a4d4", /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #83a4d4, #b6fbff)", /* Chrome 10-25, Safari 5.1-6 */
        background: "linear-gradient(to left, #62e83430, #97fa9e7d)",
        overflow: 'hidden',
        //background: `url(${backgroundShape}) no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        paddingBottom: 50,
        //border:"dashed"
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),

        color: theme.palette.text.secondary
    },
    formControl: {
        margin: theme.spacing(2),
        marginLeft: "0px",
        fullWidth: true,

    },
    title: {
        display: "block",
        fontFamily: "Poppins-Bold",
        fontSize: "25px",
        color: theme.palette.primary.main,
        lineHeight: 1.2,
        textAlign: "center",
        padding: "20px"
    },
    subTitle: {
        display: "block",
        fontFamily: "Poppins-Bold",
        fontSize: "20px",
        color: "#333333",
        lineHeight: 1.2,
        textAlign: "center",
    },
    selectors: {
        flex: "inherit",
        //border:"double"
    },
    downloadButton: {
        flex: "inherit",
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingLeft: '5px',

    },
    dateControl: {
        margin: theme.spacing(1),
        marginLeft: "0px",
        padding: "2px",


    },
    apply: {
        paddingTop: "35px",
        flex: "inherit"
    }

});




function getDataForGraph(bot_id, from_date, to_date, system, kernels, faqSystems, fls_a, fls_b, fls_c, fls_d,all_services_part_a,all_services_part_b,all_services_part_c,all_services_part_d) {

    if (bot_id == parseInt(process.env.REACT_APP_SMART_SERVICE_DESK_ID)) {
        if (system !== "ALL SERVICES" && system !== "FLS" && system !== "PIL O2C ERP") {
             if (system === "CF SRM & ARIBA PIL Self Service Procurement") {

                system = "CF SRM %26 ARIBA PIL Self Service Procurement"
            }
            try {
                var status = 200
                return axios.get(process.env.REACT_APP_DASHBOARD_SERVICE_DATA_API + from_date + "&to_date=" + to_date + "&service_name=" + system,
                    {
                        headers: {
                            "Content-Type":
                            "application/json"
                        }
                    }
                )
                    .then(function (res) {
                        try {
                            var user_assist_sub_category = [["Category", "Count"]]
                            var user_assist_messages = [["Category", "New Queries"]]
                            var feedback_comment_system = [["Feedback Comments"]]
                            var akamai_s,akamia_f,akamai_t
                            var akamai_transactions_d = [["Request Type", "Successful","UnSuccessful","Total Requests"]]
                            var  reasons_akamai = [["Reason", "Count"]]
                            var akamai_redirect_s=0,akamia_redirect_f=0,akamai_redirect_t=0;
                            var akamai_redirect_transactions_d = [["Request Type", "Successful","UnSuccessful","Total Requests"]]
                            var  reasons_akamai_redirect = [["Reason", "Count"]]
                            var  reasons_windchill = [["Reason", "Count"]]
                            var windchill_transactions_d = [["Request Type", "Successful","UnSuccessful","Total Requests"]]
                            var  reasons_m2o_ccrm_reactivate = [["Reason", "Count"]]
                            var m2o_ccrm_reactivate_transactions_d = [["Request Type", "Successful","UnSuccessful","Total Requests"]]
                           
                            if (faqSystems.includes(system) && Object.keys(res.data.user_assist_sub_catgeory).length > 0) {
                                Object.keys(res.data.user_assist_sub_catgeory).map(function (key) {
                                    user_assist_sub_category.push([key, res.data.user_assist_sub_catgeory[key]]);
                                });
                                Object.keys(res.data.user_assist_messages).map(function (key) {
                                    user_assist_messages.push([key, res.data.user_assist_messages[key].toString()]);
                                });

                            }
                            var queries = [["Password Reset", "Account Unlock","Akamai URL Flush","Akamai URL Redirect","Windchill License Reactivation","cCRM Account Reactivation","User Assist"]], users = [["Password Reset", "Account Unlock","Akamai URL Flush","Akamai URL Redirect","Windchill License Reactivation","cCRM Account Reactivation","User Assist"]], feedback = [];
                            var reasons_p = [["Reason", "Count"]], reasons_a = [["Reason", "Count"]], queries_d = [], queries_ud = [], feedback_d = [["Feedback", "Count"]], new_users_d = [["Transaction Type", "New User Count"]], users_d = [["Transaction Type", "Unique User Count"]], users_ud = [["Transaction Type", "Total Transactions"]]
                            queries.push([res.data.transactions.Pwd_S, res.data.transactions.Acc_S,res.data.transactions.URLFlush_S,res.data.transactions.URLRedirect_S,res.data.transactions.WindchillLicenseReactivation_S,res.data.transactions.M2OcCRMAccountReactivation_S,null],
                                [res.data.transactions.Pwd_F, res.data.transactions.Acc_F,res.data.transactions.URLFlush_F,res.data.transactions.URLRedirect_F,res.data.transactions.WindchillLicenseReactivation_F,res.data.transactions.M2OcCRMAccountReactivation_F,null],
                                [null, null,null,null,null,null, res.data.transactions.user_assist])

                            queries_d.push(["Transaction Type", "Successful", "UnSuccessful"],
                                ["Password Reset", res.data.transactions.Pwd_S, res.data.transactions.Pwd_F],
                                ["Account Unlock", res.data.transactions.Acc_S, res.data.transactions.Acc_F])
                            queries_ud.push(["Transaction Type", "Total Transactions"], ["User Assist", res.data.transactions.user_assist])

                            users.push([res.data.unique_user_count.Pwd, res.data.unique_user_count.Acc, res.data.unique_user_count.AkamaiURLFlush,res.data.unique_user_count.AkamaiURLRedirect,res.data.unique_user_count.WindchillLicenseReactivation,res.data.unique_user_count.M2OcCRMAccountReactivation,res.data.unique_user_count.user_assist])

                            if (!faqSystems.includes(system)) {
                                users_d.push(["Password Reset", res.data.unique_user_count.Pwd], ["Account Unlock", res.data.unique_user_count.Acc])

                                new_users_d.push(["Password Reset", res.data.new_user_count.Pwd], ["Account Unlock", res.data.new_user_count.Acc])

                            }

                            users_d.push(["User Assist", res.data.unique_user_count.user_assist])


                            new_users_d.push(["User Assist", res.data.new_user_count.user_assist])

                            res.data.feedback_comments.map(function (value) {
                                feedback_comment_system.push([value])
                            })

                            Object.keys(res.data.failure_reason.Acc_F).map(function (key) {
                                reasons_a.push([key, res.data.failure_reason.Acc_F[key]]);
                            });
                            Object.keys(res.data.failure_reason.Pwd_F).map(function (key) {
                                reasons_p.push([key, res.data.failure_reason.Pwd_F[key]]);
                            });

                            if (Object.keys(res.data.feedback).length == 0) {
                                feedback.push(["Happy", "Neutral", "Unhappy"], [0, 0, 0]);
                                feedback_d.push(["Happy", 0], ["Neutral", 0], ["Unhappy", 0])
                            }
                            else {
                                let feedback_type = [], feedback_count = []
                                Object.keys(res.data.feedback).map(function (key) {
                                    feedback_d.push([key, res.data.feedback[key]])
                                    feedback_type.push(key)
                                    feedback_count.push(res.data.feedback[key])
                                });
                                feedback.push(feedback_type, feedback_count)
                            }


                             if(system === "PIL Online Marketing"){
                                akamai_transactions_d.push(["Akamai URL Flush",res.data.transactions.URLFlush_S,res.data.transactions.URLFlush_F,res.data.transactions.URLFlush_S + res.data.transactions.URLFlush_F])
                                 users_d.push(["Akamai URL Flush", res.data.unique_user_count.AkamaiURLFlush])
                                 new_users_d.push(["Akamai URL Flush", res.data.new_user_count.AkamaiURLFlush])
                                  Object.keys(res.data.failure_reason.URLFlush_F).map(function (key) {
                                    reasons_akamai.push([key, res.data.failure_reason.URLFlush_F[key]]);
                            });
                            akamai_transactions_d.push(["Akamai URL Redirect",res.data.transactions.URLRedirect_S,res.data.transactions.URLRedirect_F,res.data.transactions.URLRedirect_S + res.data.transactions.URLRedirect_F])
                                 users_d.push(["Akamai URL Redirect", res.data.unique_user_count.AkamaiURLRedirect])
                                 new_users_d.push(["Akamai URL Redirect", res.data.new_user_count.AkamaiURLRedirect])
                                  Object.keys(res.data.failure_reason.URLRedirect_F).map(function (key) {
                                    reasons_akamai_redirect.push([key, res.data.failure_reason.URLRedirect_F[key]]);
                            });
                            }

                            if(system === "PIL cPLM"){
                                 
                                      users_d.push(["Windchill License Reactivation", res.data.unique_user_count.WindchillLicenseReactivation])
                                 new_users_d.push(["Windchill License Reactivation", res.data.new_user_count.WindchillLicenseReactivation])
                                     windchill_transactions_d.push(["Windchill License Reactivation",res.data.transactions.WindchillLicenseReactivation_S,res.data.transactions.WindchillLicenseReactivation_F,res.data.transactions.WindchillLicenseReactivation_S + res.data.transactions.WindchillLicenseReactivation_F])
                                    Object.keys(res.data.failure_reason.WindchillLicenseReactivation_F).map(function (key) {
                                      reasons_windchill.push([key, res.data.failure_reason.WindchillLicenseReactivation_F[key]]);
                                      });
                            
                            }

                            if(system === "CP M2O CHANNEL MANAGEMENT"){
                                 
                                users_d.push(["cCRM Account Reactivation", res.data.unique_user_count.M2OcCRMAccountReactivation])
                                new_users_d.push(["cCRM Account Reactivation", res.data.new_user_count.M2OcCRMAccountReactivation])
                                m2o_ccrm_reactivate_transactions_d.push(["cCRM Account Reactivation",res.data.transactions.M2OcCRMAccountReactivation_S,res.data.transactions.M2OcCRMAccountReactivation_F,res.data.transactions.M2OcCRMAccountReactivation_S + res.data.transactions.M2OcCRMAccountReactivation_F])
                                Object.keys(res.data.failure_reason.M2OcCRMAccountReactivation_F).map(function (key) {
                                reasons_m2o_ccrm_reactivate.push([key, res.data.failure_reason.M2OcCRMAccountReactivation_F[key]]);
                                });
                            }

                        } catch (error) {
                            console.log(error)
                            status = 404
                            return status;

                        }


                        return { status, queries, users, queries_d, users_d, new_users_d, feedback, feedback_d, reasons_p, reasons_a, queries_ud, user_assist_sub_category, user_assist_messages, system, feedback_comment_system,akamai_transactions_d,reasons_akamai,reasons_akamai_redirect,akamai_redirect_transactions_d,reasons_windchill,windchill_transactions_d,reasons_m2o_ccrm_reactivate,m2o_ccrm_reactivate_transactions_d };

                    }).catch(error => {
                        let status = 404;
                        return status;
                    })

            } catch (error) {
                console.log(error)
                let status = 404;
                return status;
            }
        }


        if (system === "FLS") {
            var systems = [];
            if (system === "PIL O2C ERP") {
                systems = ["PIL O2C ERP"]
            } else {
                systems = kernels.filter(e => e !== "ALL SERVICES");
            }
            try {
                var status = 200;
                return axios
                    .post(process.env.REACT_APP_DASHBOARD_CONSOLIDATED_DATA_API,
                    {
                        from_date: from_date,
                        to_date: to_date,
                        services: fls_a
                    },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                    )
                    .then(function (res) {
                        let partA, partB, partC, partD = []
                        partA = res.data

                        return axios
                            .post(process.env.REACT_APP_DASHBOARD_CONSOLIDATED_DATA_API,
                            {
                                from_date: from_date,
                                to_date: to_date,
                                services: fls_b
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            }
                            )
                            .then(function (res) {
                                partB = res.data



                                return axios
                                    .post(process.env.REACT_APP_DASHBOARD_CONSOLIDATED_DATA_API,
                                    {
                                        from_date: from_date,
                                        to_date: to_date,
                                        services: fls_c
                                    },
                                    {
                                        headers: {
                                            "Content-Type": "application/json"
                                        }
                                    }
                                    )
                                    .then(function (res) {
                                        partC = res.data


                                        return axios
                                            .post(process.env.REACT_APP_DASHBOARD_CONSOLIDATED_DATA_API,
                                            {
                                                from_date: from_date,
                                                to_date: to_date,
                                                services: fls_d
                                            },
                                            {
                                                headers: {
                                                    "Content-Type": "application/json"
                                                }
                                            }
                                            )
                                            .then(function (res) {
                                                partD = res.data

                                                let flsobject = { result: [] }
                                                flsobject.result = partA.result.concat(partB.result, partC.result, partD.result);

                                                try {
                                                    var user_assist_sub_category = [["Category", "Count"]];
                                                    var user_assist_messages = [["Category", "Data"]];
                                                    var queries = [
                                                        ["Password Reset", "Account Unlock", "User Assist"], [0, 0, null], [0, 0, null]
                                                    ],
                                                        users = [["Password Reset", "Account Unlock", "User Assist"]],
                                                        feedback = [[], []];
                                                    var reasons_p = [],
                                                        reasons_a = [],
                                                        queries_d = [],
                                                        queries_ud = [],
                                                        feedback_d = [],
                                                        users_d = [["Transaction Type", "Unique User Count"]],
                                                        users_ud = [["Transaction Type", "Total Transactions"]];
                                                    var Pwd_S = 0,
                                                        Pwd_F = 0,
                                                        Acc_S = 0,
                                                        Acc_F = 0,
                                                        user_assist = 0,
                                                        pwdUser = 0,
                                                        accUser = 0,
                                                        assistUser = 0,
                                                        new_pwdUser = 0,
                                                        new_accUser = 0,
                                                        new_assistUser = 0,
                                                        feedback_happy = 0,
                                                        feedback_unhappy = 0,
                                                        feedback_neutral = 0,
                                                        userassist_queries = 0;
                                                    var feedbackAll = [];
                                                    var all_pwd_reasons = [],
                                                        all_acc_reasons = [],
                                                        all_userassist_categories = [];
                                                    var pwd_reasons = [],
                                                        acc_reasons = [],
                                                        reason_pwd_d = [["Reason"]],
                                                        reason_acc_d = [["Reason"]],
                                                        userassist_categories = [];
                                                    var transactions = [
                                                        [""],
                                                        ["Transaction Type"],
                                                        ["Password Reset"],
                                                        ["Account Unlock"]
                                                    ];
                                                    var all_users = [
                                                        ["Transaction Type"],
                                                        ["Password Reset"],
                                                        ["Account unlock"],
                                                        ["User Assist"]
                                                    ];
                                                    var all_new_users = [
                                                        ["Transaction Type"],
                                                        ["Password Reset"],
                                                        ["Account unlock"],
                                                        ["User Assist"]
                                                    ];
                                                    var all_feedback_cases = [],
                                                        feedback_cases = [];
                                                    var all_feedback_d = [["Feedback Type"]];
                                                    var all_userassist_categories_d = [["Category"]];
                                                    var feedback_comment = [["Feedback Comment"]];
                                                    var user_assist_topics = [],
                                                        user_assist_topic_list = [];
                                                    var flsService = [["Service", "Transactions", "Total Users", "New Users", "Feedback", "Feedback Comment"]]
                                                    let feedback_fls_d = null


                                                    flsobject.result.map(system => {
                                                        if (Object.keys(system.feedback).length === 0 && system.feedback.constructor === Object) {
                                                            feedback_fls_d = "0"
                                                        } else {
                                                            feedback_fls_d = JSON.stringify(system.feedback)
                                                        }
                                                        flsService.push([system.service_name, system.transactions.user_assist, system.unique_user_count.user_assist, system.new_user_count.user_assist, feedback_fls_d, system.feedback_comments.toString()])


                                                        user_assist += system.transactions.user_assist;

                                                        assistUser += system.unique_user_count.user_assist;






                                                        Object.keys(system.feedback).map(function (key) {
                                                            if (!all_feedback_cases.includes(key)) {
                                                                all_feedback_cases.push(key);
                                                                feedback_cases.push([key]);
                                                            }
                                                        });





                                                        flsobject.result.map(system => {
                                                            let temp_pwd_reasons = [[], []];
                                                            let temp_acc_reasons = [[], []];
                                                            let temp_feedback = [[], []];
                                                            let temp_userassist = [[], []];



                                                            Object.keys(system.feedback).map(function (key) {
                                                                temp_feedback[0].push(key);
                                                                temp_feedback[1].push(system.feedback[key]);
                                                            });
                                                            all_feedback_cases.map(function (value, index, array) {
                                                                if (temp_feedback[0].includes(value)) {
                                                                    feedback_cases[index].push(
                                                                        temp_feedback[1][temp_feedback[0].indexOf(value)]
                                                                    );
                                                                } else {
                                                                    feedback_cases[index].push(0);
                                                                }
                                                            });


                                                        });

                                                        var temp_happy = 0, temp_neutral = 0, temp_unhappy = 0;

                                                        all_feedback_d[0] = all_feedback_d[0].concat("Total");
                                                        for (var i = 1; i < all_feedback_d.length; i++) {
                                                            if (all_feedback_d[i][0].toUpperCase() === "HAPPY") {
                                                                for (j = 1; j < all_feedback_d[i].length; j++) {
                                                                    temp_happy = temp_happy + all_feedback_d[i][j];
                                                                }
                                                                all_feedback_d[i] = all_feedback_d[i].concat(temp_happy);
                                                            }
                                                            if (all_feedback_d[i][0].toUpperCase() === "NEUTRAL") {
                                                                for (var j = 1; j < all_feedback_d[i].length; j++) {
                                                                    temp_neutral = temp_neutral + all_feedback_d[i][j];
                                                                }
                                                                all_feedback_d[i] = all_feedback_d[i].concat(temp_neutral);
                                                            }
                                                            if (all_feedback_d[i][0].toUpperCase() === "UNHAPPY") {
                                                                for (j = 1; j < all_feedback_d[i].length; j++) {
                                                                    temp_unhappy = temp_unhappy + all_feedback_d[i][j];
                                                                }
                                                                all_feedback_d[i] = all_feedback_d[i].concat(temp_unhappy);
                                                            }
                                                        }



                                                        feedbackAll.push(
                                                            feedback_happy,
                                                            feedback_neutral,
                                                            feedback_unhappy
                                                        );



                                                        var pwdReason = [];



                                                        Object.keys(system.feedback).map(function (key) {
                                                            if (feedback_d.length == 0) {
                                                                feedback_d.push([key, system.feedback[key]]);
                                                                feedback[0].push(key);
                                                                feedback[1].push(system.feedback[key]);
                                                            } else {
                                                                let flag = 0;
                                                                for (var i = 0; i < feedback_d.length; i++) {
                                                                    if (feedback_d[i][0] === key) {
                                                                        feedback_d[i][1] += system.feedback[key];
                                                                        feedback[1][i] += system.feedback[key];
                                                                        flag += 1;
                                                                    }
                                                                }
                                                                if (flag == 0) {
                                                                    feedback_d.push([key, system.feedback[key]]);
                                                                    feedback[0].push(key);
                                                                    feedback[1].push(system.feedback[key]);
                                                                }
                                                            }
                                                        });
                                                    });

                                                    queries.push([null, null, user_assist])
                                                    users.push([0, 0, assistUser])
                                                } catch (error) {
                                                    console.log(error);
                                                    status = 404;
                                                }

                                                return {
                                                    user_assist_topics,
                                                    feedback_comment,
                                                    all_userassist_categories_d,
                                                    all_new_users,
                                                    all_users,
                                                    all_feedback_d,
                                                    transactions,
                                                    reason_pwd_d,
                                                    reason_acc_d,
                                                    status,
                                                    queries,
                                                    users,
                                                    queries_d,
                                                    users_d,
                                                    feedback,
                                                    feedback_d,
                                                    reasons_p,
                                                    reasons_a,
                                                    queries_ud,
                                                    user_assist_sub_category,
                                                    user_assist_messages,
                                                    flsService
                                                };


                                            })
                                            .catch(error => {
                                                let status = 404;
                                                return status;
                                            });

                                    })
                                    .catch(error => {
                                        let status = 404;
                                        return status;
                                    });

                            })
                            .catch(error => {
                                let status = 404;
                                return status;
                            });

                    })
                    .catch(error => {
                        let status = 404;
                        return status;
                    });
            } catch (error) {
                console.log(error);
                let status = 404;
                return status;
            }
        }

        

        if (system === "ALL SERVICES") {
            var systems = [];
            // if (system === "PIL O2C ERP") {
            //     systems = ["PIL O2C ERP"]
            // } else {
                systems = kernels.filter(e => e !== "ALL SERVICES");
           // }
            try {
                var status = 200;
                return axios
                    .post(process.env.REACT_APP_DASHBOARD_CONSOLIDATED_DATA_API,
                    {
                        from_date: from_date,
                        to_date: to_date,
                        services: all_services_part_a
                    },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                    )
                    .then(function (res) {
                        let partA, partB, partC, partD = []
                        partA = res.data

                        return axios
                            .post(process.env.REACT_APP_DASHBOARD_CONSOLIDATED_DATA_API,
                            {
                                from_date: from_date,
                                to_date: to_date,
                                services: all_services_part_b
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            }
                            )
                            .then(function (res) {
                                partB = res.data



                                return axios
                                    .post(process.env.REACT_APP_DASHBOARD_CONSOLIDATED_DATA_API,
                                    {
                                        from_date: from_date,
                                        to_date: to_date,
                                        services: all_services_part_c
                                    },
                                    {
                                        headers: {
                                            "Content-Type": "application/json"
                                        }
                                    }
                                    )
                                    .then(function (res) {
                                        partC = res.data


                                        return axios
                                            .post(process.env.REACT_APP_DASHBOARD_CONSOLIDATED_DATA_API,
                                            {
                                                from_date: from_date,
                                                to_date: to_date,
                                                services: all_services_part_d
                                            },
                                            {
                                                headers: {
                                                    "Content-Type": "application/json"
                                                }
                                            }
                                            )
                                            .then(function (response) {
                                                partD = response.data

                                                var allServices = { result: [] }
                                                allServices.result = partA.result.concat(partB.result, partC.result, partD.result);

                                                try {
                            var user_assist_sub_category = [["Category", "Count"]]
                            var user_assist_messages = [["Category", "Data"]]
                            var queries = [["Password Reset", "Account Unlock","Akamai URL Flush","Akamai URL Redirect","Windchill License Reactivation","cCRM Account Reactivation","User Assist"]], users = [["Password Reset", "Account Unlock","Akamai URL Flush","Akamai URL Redirect","Windchill License Reactivation", "cCRM Account Reactivation","User Assist"]], feedback = [[], []];
                            var reasons_p = [], reasons_a = [], queries_d = [], queries_ud = [], feedback_d = [], users_d = [["Transaction Type", "Unique User Count"]], users_ud = [["Transaction Type", "Total Transactions"]]
                            var Pwd_S = 0, Pwd_F = 0, Acc_S = 0, Acc_F = 0, user_assist = 0, pwdUser = 0, accUser = 0, assistUser = 0,akamaiUser = 0,newakamaiUser =0,akamaiRedirectUser = 0,newakamaiRedirectUser =0,new_pwdUser = 0, new_accUser = 0, new_assistUser = 0, feedback_happy = 0, feedback_unhappy = 0, feedback_neutral = 0, userassist_queries = 0;
                            var feedbackAll = [];
                            var all_pwd_reasons = [], all_acc_reasons = [], all_userassist_categories = []
                            var pwd_reasons = [], acc_reasons = [], reason_pwd_d = [["Reason"]], reason_acc_d = [["Reason"]], userassist_categories = []
                            var transactions = [[""], ["Transaction Type"], ["Password Reset"], ["Account Unlock"]];
                            var all_users = [["Transaction Type"], ["Password Reset"], ["Account unlock"], ["User Assist"],["Akamai URL Flush"],["Akamai URL Redirect"],["Windchill License Reactivation"],["cCRM Account Reactivation"]]
                            var all_new_users = [["Transaction Type"], ["Password Reset"], ["Account unlock"], ["User Assist"],["Akamai URL Flush"],["Akamai URL Redirect"],["Windchill License Reactivation"],["cCRM Account Reactivation"]]
                            var all_feedback_cases = [], feedback_cases = []
                            var all_feedback_d = [["Feedback Type"]]
                            var all_userassist_categories_d = [["Category"]]
                            var feedback_comment = [["Feedback Comment"]]
                            var user_assist_topics = [], user_assist_topic_list = []
                            var akamai_s = 0,akamai_f = 0 ,akamai_t = 0,akamai_redirect_s = 0,akamai_redirect_f = 0 ,akamai_redirect_t = 0;
                            var akamai_transactions_d = [["Request Type", "Successful","UnSuccessful","Total Requests"]]
                            var akamai_transactions_redirect_d = [["Request Type", "Successful","UnSuccessful","Total Requests"]]
                            var  reasons_akamai = [["Reason", "Count"]];
                            var reasons_windchill = [["Reason", "Count"]];
                            var reasons_m2o_ccrm_reactivate = [["Reason", "Count"]];
                            var  reasons_akamai_redirect = [["Reason", "Count"]];
                            var windchill_transactions_d = [["Request Type", "Successful","UnSuccessful","Total Requests"]]
                            var m2o_ccrm_reactivate_transactions_d = [["Request Type", "Successful","UnSuccessful","Total Requests"]]
                            var WindchillLicenseReactivation_F=0,WindchillLicenseReactivation_S=0,WindchillLicenseReactivation_T=0,windchillUser=0,newwindchillUser=0;
                            var M2OcCRMAccountReactivation_F=0,M2OcCRMAccountReactivation_S=0,m2occrmReactivateUser=0,newm2occrmReactivateUser=0;

                            allServices.result.map(system => {
                                all_users[0].push(system.service_name)
                                all_users[1].push(system.unique_user_count.Pwd)
                                all_users[2].push(system.unique_user_count.Acc)
                                all_users[3].push(system.unique_user_count.user_assist)

                                all_new_users[0].push(system.service_name)
                                all_new_users[1].push(system.new_user_count.Pwd)
                                all_new_users[2].push(system.new_user_count.Acc)
                                all_new_users[3].push(system.new_user_count.user_assist)

                               if(system.service_name.toUpperCase() === "PIL CPLM"){
                                     WindchillLicenseReactivation_S +=  system.transactions.WindchillLicenseReactivation_S
                                     WindchillLicenseReactivation_F +=  system.transactions.WindchillLicenseReactivation_F
                                     all_users[6].push(system.unique_user_count.WindchillLicenseReactivation)
                                     all_new_users[6].push(system.new_user_count.WindchillLicenseReactivation)
                                     windchillUser += system.unique_user_count.WindchillLicenseReactivation
                                     newwindchillUser += system.new_user_count.WindchillLicenseReactivation
                                     windchill_transactions_d.push(["Windchill License Reactivation",system.transactions.WindchillLicenseReactivation_S,system.transactions.WindchillLicenseReactivation_F,system.transactions.WindchillLicenseReactivation_S + system.transactions.WindchillLicenseReactivation_F])
                                    Object.keys(system.failure_reason.WindchillLicenseReactivation_F).map(function (key) {
                                      reasons_windchill.push([key, system.failure_reason.WindchillLicenseReactivation_F[key]]);
                                      });
                                }else{
                                    all_users[6].push("na")
                                     all_new_users[6].push("na")
                                }

                                if(system.service_name.toUpperCase() === "CP M2O CHANNEL MANAGEMENT"){
                                    M2OcCRMAccountReactivation_S +=  system.transactions.M2OcCRMAccountReactivation_S
                                    M2OcCRMAccountReactivation_F +=  system.transactions.M2OcCRMAccountReactivation_F
                                    all_users[7].push(system.unique_user_count.M2OcCRMAccountReactivation)
                                    all_new_users[7].push(system.new_user_count.M2OcCRMAccountReactivation)
                                    m2occrmReactivateUser += system.unique_user_count.M2OcCRMAccountReactivation
                                    newm2occrmReactivateUser += system.new_user_count.M2OcCRMAccountReactivation
                                    m2o_ccrm_reactivate_transactions_d.push(["cCRM Account Reactivation",system.transactions.M2OcCRMAccountReactivation_S,system.transactions.M2OcCRMAccountReactivation_F,system.transactions.M2OcCRMAccountReactivation_S + system.transactions.M2OcCRMAccountReactivation_F])
                                    Object.keys(system.failure_reason.M2OcCRMAccountReactivation_F).map(function (key) {
                                        reasons_m2o_ccrm_reactivate.push([key, system.failure_reason.M2OcCRMAccountReactivation_F[key]]);
                                    });
                               }else{
                                   all_users[7].push("na")
                                    all_new_users[7].push("na")
                               }

                                if(system.service_name.toUpperCase() === "PIL ONLINE MARKETING"){
                                     all_users[4].push(system.unique_user_count.AkamaiURLFlush)
                                     all_new_users[4].push(system.new_user_count.AkamaiURLFlush)
                                     all_users[5].push(system.unique_user_count.AkamaiURLRedirect)
                                     all_new_users[5].push(system.new_user_count.AkamaiURLRedirect)
                                     akamaiUser += system.unique_user_count.AkamaiURLFlush
                                     newakamaiUser += system.new_user_count.AkamaiURLFlush
                                     akamaiRedirectUser += system.unique_user_count.AkamaiURLRedirect
                                     newakamaiRedirectUser += system.new_user_count.AkamaiURLRedirect
                                    akamai_transactions_d.push(["Akamai URL Flush",system.transactions.URLFlush_S,system.transactions.URLFlush_F,system.transactions.URLFlush_S + system.transactions.URLFlush_F])
                                    akamai_transactions_d.push(["Akamai URL Redirect",system.transactions.URLRedirect_S,system.transactions.URLRedirect_F,system.transactions.URLRedirect_S + system.transactions.URLRedirect_F])
                                    Object.keys(system.failure_reason.URLFlush_F).map(function (key) {
                                      reasons_akamai.push([key, system.failure_reason.URLFlush_F[key]]);
                                      });
                                       Object.keys(system.failure_reason.URLRedirect_F).map(function (key) {
                                      reasons_akamai_redirect.push([key, system.failure_reason.URLRedirect_F[key]]);
                                         });
                                }else{
                                    all_users[4].push("na")
                                     all_new_users[4].push("na")
                                     all_users[5].push("na")
                                     all_new_users[5].push("na")
                                }

                                    all_userassist_categories_d[0].push(system.service_name)
                                

                                if (!faqSystems.includes(system.service_name)) {
                                    reason_pwd_d[0].push(system.service_name)
                                    reason_acc_d[0].push(system.service_name)
                                }
                                all_feedback_d[0].push(system.service_name)
                                Pwd_S += system.transactions.Pwd_S
                                Pwd_F += system.transactions.Pwd_F
                                if(system.service_name.toUpperCase() == "PIL ONLINE MARKETING"){
                                akamai_s +=  system.transactions.URLFlush_S
                                akamai_f +=  system.transactions.URLFlush_F
                                akamai_redirect_s +=  system.transactions.URLRedirect_S
                                akamai_redirect_f +=  system.transactions.URLRedirect_F
                                }
                                Acc_S += system.transactions.Acc_S
                                Acc_F += system.transactions.Acc_F
                                user_assist += system.transactions.user_assist
                                pwdUser += system.unique_user_count.Pwd
                                accUser += system.unique_user_count.Acc
                                assistUser += system.unique_user_count.user_assist
                                new_pwdUser += system.new_user_count.Pwd
                                new_accUser += system.new_user_count.Acc
                                new_assistUser += system.new_user_count.user_assist
                                if (!faqSystems.includes(system.service_name)) {

                                    transactions[0].push(system.service_name, "")
                                    transactions[1].push("Successful", "UnSuccessful")
                                    transactions[2].push(system.transactions.Pwd_S, system.transactions.Pwd_F)
                                    transactions[3].push(system.transactions.Acc_S, system.transactions.Acc_F)
                                }

                                system.feedback_comments.map(function (value) {
                                    feedback_comment.push([value])
                                })
                                Object.keys(system.failure_reason.Pwd_F).map(function (key) {

                                    if (!all_pwd_reasons.includes(key)) {
                                        all_pwd_reasons.push(key)
                                        pwd_reasons.push([key])
                                    }

                                })

                                Object.keys(system.failure_reason.Acc_F).map(function (key) {

                                    if (!all_acc_reasons.includes(key)) {
                                        all_acc_reasons.push(key)
                                        acc_reasons.push([key])
                                    }

                                })

                                Object.keys(system.feedback).map(function (key) {

                                    if (!all_feedback_cases.includes(key)) {
                                        all_feedback_cases.push(key)
                                        feedback_cases.push([key])
                                    }

                                })

                                Object.keys(system.user_assist_sub_catgeory).map(function (key) {

                                    if (!all_userassist_categories.includes(key)) {
                                        all_userassist_categories.push(key)
                                        userassist_categories.push([key])
                                    }

                                })
                                //}



                                Object.keys(system.user_assist_messages).map(function (key) {

                                    if (!user_assist_topic_list.includes(key)) {
                                        user_assist_topic_list.push(key)
                                        user_assist_topics.push([key, system.user_assist_messages[key].toString()])
                                    } else {
                                        user_assist_topics[user_assist_topic_list.indexOf(key)][1] = user_assist_topics[user_assist_topic_list.indexOf(key)][1].concat(system.user_assist_messages[key].toString())
                                    }

                                })


                            })



                            allServices.result.map(system => {
                                let temp_pwd_reasons = [[], []]
                                let temp_acc_reasons = [[], []]
                                let temp_feedback = [[], []]
                                let temp_userassist = [[], []]
                                if (!faqSystems.includes(system.service_name)) {

                                    Object.keys(system.failure_reason.Pwd_F).map(function (key) {

                                        temp_pwd_reasons[0].push(key)
                                        temp_pwd_reasons[1].push(system.failure_reason.Pwd_F[key])

                                    })
                                    all_pwd_reasons.map(function (value, index, array) {
                                        if (temp_pwd_reasons[0].includes(value)) {
                                            pwd_reasons[index].push(temp_pwd_reasons[1][temp_pwd_reasons[0].indexOf(value)])
                                        } else {
                                            pwd_reasons[index].push(0)
                                        }
                                    })


                                    Object.keys(system.failure_reason.Acc_F).map(function (key) {

                                        temp_acc_reasons[0].push(key)
                                        temp_acc_reasons[1].push(system.failure_reason.Acc_F[key])

                                    })
                                    all_acc_reasons.map(function (value, index, array) {
                                        if (temp_acc_reasons[0].includes(value)) {
                                            acc_reasons[index].push(temp_acc_reasons[1][temp_acc_reasons[0].indexOf(value)])
                                        } else {
                                            acc_reasons[index].push(0)
                                        }
                                    })
                                }

                                Object.keys(system.feedback).map(function (key) {

                                    temp_feedback[0].push(key)
                                    temp_feedback[1].push(system.feedback[key])

                                })
                                all_feedback_cases.map(function (value, index, array) {
                                    if (temp_feedback[0].includes(value)) {
                                        feedback_cases[index].push(temp_feedback[1][temp_feedback[0].indexOf(value)])
                                    } else {
                                        feedback_cases[index].push(0)
                                    }
                                })

                               
                                Object.keys(system.user_assist_sub_catgeory).map(function (key) {
                                    temp_userassist[0].push(key)
                                    temp_userassist[1].push(system.user_assist_sub_catgeory[key])

                                })




                                all_userassist_categories.map(function (value, index, array) {
                                    if (temp_userassist[0].includes(value)) {
                                        userassist_categories[index].push(temp_userassist[1][temp_userassist[0].indexOf(value)])
                                    } else {
                                        userassist_categories[index].push(0)
                                    }
                                })
                                //}



                            });

                            reason_acc_d = reason_acc_d.concat(acc_reasons)
                            reason_pwd_d = reason_pwd_d.concat(pwd_reasons)
                            all_feedback_d = all_feedback_d.concat(feedback_cases)
                            all_userassist_categories_d = all_userassist_categories_d.concat(userassist_categories)
                            transactions[0] = transactions[0].concat("Total Transactions", "")
                            transactions[1] = transactions[1].concat("Successful", "UnSuccessful")
                            transactions[2] = transactions[2].concat(Pwd_S, Pwd_F)
                            transactions[3] = transactions[3].concat(Acc_S, Acc_F)
                            all_users[0] = all_users[0].concat("Total")
                            all_users[1] = all_users[1].concat(pwdUser)
                            all_users[2] = all_users[2].concat(accUser)
                            all_users[3] = all_users[3].concat(assistUser)
                            all_users[4] = all_users[4].concat(akamaiUser)
                            all_users[5] = all_users[5].concat(akamaiRedirectUser)
                            all_users[6] = all_users[6].concat(windchillUser)
                            all_users[7] = all_users[7].concat(m2occrmReactivateUser)
                            all_new_users[0] = all_new_users[0].concat("Total")
                            all_new_users[1] = all_new_users[1].concat(new_pwdUser)
                            all_new_users[2] = all_new_users[2].concat(new_accUser)
                            all_new_users[3] = all_new_users[3].concat(new_assistUser)
                            all_new_users[4] = all_new_users[4].concat(newakamaiUser)
                            all_new_users[5] = all_new_users[5].concat(newakamaiRedirectUser)
                            all_new_users[6] = all_new_users[6].concat(newwindchillUser)
                            all_new_users[7] = all_new_users[7].concat(newm2occrmReactivateUser)
                            var temp_happy = 0, temp_neutral = 0, temp_unhappy = 0;
                            all_feedback_d[0] = all_feedback_d[0].concat("Total")
                            for (var i = 1; i < all_feedback_d.length; i++) {
                                if (all_feedback_d[i][0].toUpperCase() === "HAPPY") {
                                    for (j = 1; j < all_feedback_d[i].length; j++) {
                                        temp_happy = temp_happy + all_feedback_d[i][j]
                                    }
                                    all_feedback_d[i] = all_feedback_d[i].concat(temp_happy)
                                }
                                if (all_feedback_d[i][0].toUpperCase() === "NEUTRAL") {
                                    for (var j = 1; j < all_feedback_d[i].length; j++) {
                                        temp_neutral = temp_neutral + all_feedback_d[i][j]
                                    }
                                    all_feedback_d[i] = all_feedback_d[i].concat(temp_neutral)
                                }
                                if (all_feedback_d[i][0].toUpperCase() === "UNHAPPY") {
                                    for (j = 1; j < all_feedback_d[i].length; j++) {
                                        temp_unhappy = temp_unhappy + all_feedback_d[i][j]
                                    }
                                    all_feedback_d[i] = all_feedback_d[i].concat(temp_unhappy)
                                }
                            }

                            
                            


                            user_assist_topics.unshift(["Category", "New Topics"])
                            feedbackAll.push(feedback_happy, feedback_neutral, feedback_unhappy)
                           
                            queries.push([Pwd_S, Acc_S,akamai_s,akamai_redirect_s,WindchillLicenseReactivation_S,M2OcCRMAccountReactivation_S,null],
                                [Pwd_F, Acc_F,akamai_f,akamai_redirect_f,WindchillLicenseReactivation_F,M2OcCRMAccountReactivation_F,null],
                                [null, null,null,null,null,null, user_assist])

                            queries_d.push(["Transaction Type", "Successful", "UnSuccessful"],
                                ["Password Reset", Pwd_S, Pwd_F],
                                ["Account Unlock", Acc_S, Acc_F])
                            queries_ud.push(["Transaction Type", "Total Transactions"], ["User Assist", user_assist])
                            users.push([pwdUser, accUser,akamaiUser,akamaiRedirectUser,windchillUser,m2occrmReactivateUser, assistUser])
                            users_d.push(["Password Reset", pwdUser], ["Account Unlock", accUser],
                                ["User Assist", assistUser]
                            )
                            


                            var pwdReason = []
                            //test
                            allServices.result.map(system => {

                                Object.keys(system.failure_reason.Acc_F).map(function (key) {
                                    if (reasons_a.length == 0) {
                                        reasons_a.push([key, system.failure_reason.Acc_F[key]])
                                    }
                                    else {
                                        let flag = 0;
                                        for (var i = 0; i < reasons_a.length; i++) {
                                            if (reasons_a[i][0] === key) {
                                                reasons_a[i][1] += system.failure_reason.Acc_F[key]
                                                flag += 1
                                            }
                                        }
                                        if (flag == 0) {
                                            reasons_a.push([key, system.failure_reason.Acc_F[key]])
                                        }
                                    }

                                });

                                Object.keys(system.failure_reason.Pwd_F).map(function (key) {
                                    if (reasons_p.length == 0) {
                                        reasons_p.push([key, system.failure_reason.Pwd_F[key]])
                                    }
                                    else {
                                        let flag = 0;
                                        for (var i = 0; i < reasons_p.length; i++) {
                                            if (reasons_p[i][0] === key) {
                                                reasons_p[i][1] += system.failure_reason.Pwd_F[key]
                                                flag += 1
                                            }
                                        }
                                        if (flag == 0) {
                                            reasons_p.push([key, system.failure_reason.Pwd_F[key]])
                                        }
                                    }

                                });

                                Object.keys(system.feedback).map(function (key) {
                                    if (feedback_d.length == 0) {
                                        feedback_d.push([key, system.feedback[key]])
                                        feedback[0].push(key)
                                        feedback[1].push(system.feedback[key])
                                    }
                                    else {
                                        let flag = 0;
                                        for (var i = 0; i < feedback_d.length; i++) {
                                            if (feedback_d[i][0] === key) {
                                                feedback_d[i][1] += system.feedback[key]
                                                feedback[1][i] += system.feedback[key]
                                                flag += 1
                                            }
                                        }

                                        if (flag == 0) {
                                            feedback_d.push([key, system.feedback[key]])
                                            feedback[0].push(key)
                                            feedback[1].push(system.feedback[key])
                                        }
                                    }

                                });

                            })


                            feedback_d.unshift(["Feedback", "Count"])
                            reasons_a.unshift(["Reason", "Count"])
                            reasons_p.unshift(["Reason", "Count"])

                        } catch (error) {
                            console.log(error)
                            status = 404

                        }






                        return { user_assist_topics, feedback_comment, all_userassist_categories_d, all_new_users, all_users, all_feedback_d, transactions, reason_pwd_d, reason_acc_d, status, queries, users, queries_d, users_d, feedback, feedback_d, reasons_p, reasons_a, queries_ud, user_assist_sub_category, user_assist_messages,akamai_transactions_d,reasons_akamai,reasons_akamai_redirect,akamai_transactions_redirect_d,windchill_transactions_d,reasons_windchill,m2o_ccrm_reactivate_transactions_d,reasons_m2o_ccrm_reactivate };



                                            })
                                            .catch(error => {
                                                let status = 404;
                                                return status;
                                            });

                                    })
                                    .catch(error => {
                                        let status = 404;
                                        return status;
                                    });

                            })
                            .catch(error => {
                                let status = 404;
                                return status;
                            });

                    })
                    .catch(error => {
                        let status = 404;
                        return status;
                    });
            } catch (error) {
                console.log(error);
                let status = 404;
                return status;
            }
        }



        else {
            var systems = [];
            if (system === "PIL O2C ERP") {
                systems = ["PIL O2C ERP"]
            }
            else {
                systems = kernels.filter(e => e !== "ALL SERVICES");
                let pos = system.indexOf("CF SRM & ARIBA PIL Self Service Procurement")
                if (pos !== -1) {
                    systems[pos] = "CF SRM %26 ARIBA PIL Self Service Procurement"
                }

            }
            try {
                var status = 200
                return axios.post(process.env.REACT_APP_DASHBOARD_CONSOLIDATED_DATA_API,
                    {
                        from_date: from_date,
                        to_date: to_date,
                        services: systems
                    },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                )
                    .then(function (res) {
                        try {
                            var user_assist_sub_category = [["Category", "Count"]]
                            var user_assist_messages = [["Category", "Data"]]
                            var queries = [["Password Reset", "Account Unlock","Akamai URL Flush","User Assist"]], users = [["Password Reset", "Account Unlock","Akamai URL Flush", "User Assist"]], feedback = [[], []];
                            var reasons_p = [], reasons_a = [], queries_d = [], queries_ud = [], feedback_d = [], users_d = [["Transaction Type", "Unique User Count"]], users_ud = [["Transaction Type", "Total Transactions"]]
                            var Pwd_S = 0, Pwd_F = 0, Acc_S = 0, Acc_F = 0, user_assist = 0, pwdUser = 0, accUser = 0, assistUser = 0,akamaiUser = 0,newakamaiUser =0,new_pwdUser = 0, new_accUser = 0, new_assistUser = 0, feedback_happy = 0, feedback_unhappy = 0, feedback_neutral = 0, userassist_queries = 0;
                            var feedbackAll = [];
                            var all_pwd_reasons = [], all_acc_reasons = [], all_userassist_categories = []
                            var pwd_reasons = [], acc_reasons = [], reason_pwd_d = [["Reason"]], reason_acc_d = [["Reason"]], userassist_categories = []
                            var transactions = [[""], ["Transaction Type"], ["Password Reset"], ["Account Unlock"]];
                            var all_users = [["Transaction Type"], ["Password Reset"], ["Account unlock"], ["User Assist"],["Akamai URL Flush"]]
                            var all_new_users = [["Transaction Type"], ["Password Reset"], ["Account unlock"], ["User Assist"],["Akamai URL Flush"]]
                            var all_feedback_cases = [], feedback_cases = []
                            var all_feedback_d = [["Feedback Type"]]
                            var all_userassist_categories_d = [["Category"]]
                            var feedback_comment = [["Feedback Comment"]]
                            var user_assist_topics = [], user_assist_topic_list = []
                            var akamai_s = 0,akamai_f = 0 ,akamai_t = 0;
                            var akamai_transactions_d = [["Request Type", "Successful","UnSuccessful","Total Requests"]]
                            var  reasons_akamai = [["Reason", "Count"]]

                            res.data.result.map(system => {
                                all_users[0].push(system.service_name)
                                all_users[1].push(system.unique_user_count.Pwd)
                                all_users[2].push(system.unique_user_count.Acc)
                                all_users[3].push(system.unique_user_count.user_assist)

                                all_new_users[0].push(system.service_name)
                                all_new_users[1].push(system.new_user_count.Pwd)
                                all_new_users[2].push(system.new_user_count.Acc)
                                all_new_users[3].push(system.new_user_count.user_assist)

                             

                                if(system.service_name.toUpperCase() === "PIL ONLINE MARKETING"){
                                     all_users[4].push(system.unique_user_count.AkamaiURLFlush)
                                     all_new_users[4].push(system.new_user_count.AkamaiURLFlush)
                                     akamaiUser += system.unique_user_count.AkamaiURLFlush
                                     newakamaiUser += system.new_user_count.AkamaiURLFlush
                                    akamai_transactions_d.push(["Akamai URL Flush",system.transactions.URLFlush_S,system.transactions.URLFlush_F,system.transactions.URLFlush_S + system.transactions.URLFlush_F])
                                     
                                    Object.keys(system.failure_reason.URLFlush_F).map(function (key) {
                                     reasons_akamai.push([key, system.failure_reason.URLFlush_F[key]]);
                            });

                                }else{
                                    all_users[4].push("na")
                                     all_new_users[4].push("na")
                                }

                                    all_userassist_categories_d[0].push(system.service_name)
                                

                                if (!faqSystems.includes(system.service_name)) {
                                    reason_pwd_d[0].push(system.service_name)
                                    reason_acc_d[0].push(system.service_name)
                                }
                                all_feedback_d[0].push(system.service_name)
                                Pwd_S += system.transactions.Pwd_S
                                Pwd_F += system.transactions.Pwd_F
                                if(system.service_name.toUpperCase() == "PIL ONLINE MARKETING"){
                                akamai_s +=  system.transactions.URLFlush_S
                                akamai_f +=  system.transactions.URLFlush_F
                                }
                                Acc_S += system.transactions.Acc_S
                                Acc_F += system.transactions.Acc_F
                                user_assist += system.transactions.user_assist
                                pwdUser += system.unique_user_count.Pwd
                                accUser += system.unique_user_count.Acc
                                assistUser += system.unique_user_count.user_assist
                                new_pwdUser += system.new_user_count.Pwd
                                new_accUser += system.new_user_count.Acc
                                new_assistUser += system.new_user_count.user_assist
                                if (!faqSystems.includes(system.service_name)) {

                                    transactions[0].push(system.service_name, "")
                                    transactions[1].push("Successful", "UnSuccessful")
                                    transactions[2].push(system.transactions.Pwd_S, system.transactions.Pwd_F)
                                    transactions[3].push(system.transactions.Acc_S, system.transactions.Acc_F)
                                }

                                system.feedback_comments.map(function (value) {
                                    feedback_comment.push([value])
                                })
                                Object.keys(system.failure_reason.Pwd_F).map(function (key) {

                                    if (!all_pwd_reasons.includes(key)) {
                                        all_pwd_reasons.push(key)
                                        pwd_reasons.push([key])
                                    }

                                })

                                Object.keys(system.failure_reason.Acc_F).map(function (key) {

                                    if (!all_acc_reasons.includes(key)) {
                                        all_acc_reasons.push(key)
                                        acc_reasons.push([key])
                                    }

                                })

                                Object.keys(system.feedback).map(function (key) {

                                    if (!all_feedback_cases.includes(key)) {
                                        all_feedback_cases.push(key)
                                        feedback_cases.push([key])
                                    }

                                })

                                Object.keys(system.user_assist_sub_catgeory).map(function (key) {

                                    if (!all_userassist_categories.includes(key)) {
                                        all_userassist_categories.push(key)
                                        userassist_categories.push([key])
                                    }

                                })
                                //}



                                Object.keys(system.user_assist_messages).map(function (key) {

                                    if (!user_assist_topic_list.includes(key)) {
                                        user_assist_topic_list.push(key)
                                        user_assist_topics.push([key, system.user_assist_messages[key].toString()])
                                    } else {
                                        user_assist_topics[user_assist_topic_list.indexOf(key)][1] = user_assist_topics[user_assist_topic_list.indexOf(key)][1].concat(system.user_assist_messages[key].toString())
                                    }

                                })


                            })



                            res.data.result.map(system => {
                                let temp_pwd_reasons = [[], []]
                                let temp_acc_reasons = [[], []]
                                let temp_feedback = [[], []]
                                let temp_userassist = [[], []]
                                if (!faqSystems.includes(system.service_name)) {

                                    Object.keys(system.failure_reason.Pwd_F).map(function (key) {

                                        temp_pwd_reasons[0].push(key)
                                        temp_pwd_reasons[1].push(system.failure_reason.Pwd_F[key])

                                    })
                                    all_pwd_reasons.map(function (value, index, array) {
                                        if (temp_pwd_reasons[0].includes(value)) {
                                            pwd_reasons[index].push(temp_pwd_reasons[1][temp_pwd_reasons[0].indexOf(value)])
                                        } else {
                                            pwd_reasons[index].push(0)
                                        }
                                    })


                                    Object.keys(system.failure_reason.Acc_F).map(function (key) {

                                        temp_acc_reasons[0].push(key)
                                        temp_acc_reasons[1].push(system.failure_reason.Acc_F[key])

                                    })
                                    all_acc_reasons.map(function (value, index, array) {
                                        if (temp_acc_reasons[0].includes(value)) {
                                            acc_reasons[index].push(temp_acc_reasons[1][temp_acc_reasons[0].indexOf(value)])
                                        } else {
                                            acc_reasons[index].push(0)
                                        }
                                    })
                                }

                                Object.keys(system.feedback).map(function (key) {

                                    temp_feedback[0].push(key)
                                    temp_feedback[1].push(system.feedback[key])

                                })
                                all_feedback_cases.map(function (value, index, array) {
                                    if (temp_feedback[0].includes(value)) {
                                        feedback_cases[index].push(temp_feedback[1][temp_feedback[0].indexOf(value)])
                                    } else {
                                        feedback_cases[index].push(0)
                                    }
                                })

                               
                                Object.keys(system.user_assist_sub_catgeory).map(function (key) {
                                    temp_userassist[0].push(key)
                                    temp_userassist[1].push(system.user_assist_sub_catgeory[key])

                                })




                                all_userassist_categories.map(function (value, index, array) {
                                    if (temp_userassist[0].includes(value)) {
                                        userassist_categories[index].push(temp_userassist[1][temp_userassist[0].indexOf(value)])
                                    } else {
                                        userassist_categories[index].push(0)
                                    }
                                })
                                //}



                            });

                            reason_acc_d = reason_acc_d.concat(acc_reasons)
                            reason_pwd_d = reason_pwd_d.concat(pwd_reasons)
                            all_feedback_d = all_feedback_d.concat(feedback_cases)
                            all_userassist_categories_d = all_userassist_categories_d.concat(userassist_categories)
                            transactions[0] = transactions[0].concat("Total Transactions", "")
                            transactions[1] = transactions[1].concat("Successful", "UnSuccessful")
                            transactions[2] = transactions[2].concat(Pwd_S, Pwd_F)
                            transactions[3] = transactions[3].concat(Acc_S, Acc_F)
                            all_users[0] = all_users[0].concat("Total")
                            all_users[1] = all_users[1].concat(pwdUser)
                            all_users[2] = all_users[2].concat(accUser)
                            all_users[3] = all_users[3].concat(assistUser)
                            all_users[4] = all_users[4].concat(akamaiUser)
                            all_new_users[0] = all_new_users[0].concat("Total")
                            all_new_users[1] = all_new_users[1].concat(new_pwdUser)
                            all_new_users[2] = all_new_users[2].concat(new_accUser)
                            all_new_users[3] = all_new_users[3].concat(new_assistUser)
                            all_new_users[4] = all_new_users[4].concat(newakamaiUser)
                            var temp_happy = 0, temp_neutral = 0, temp_unhappy = 0;
                            all_feedback_d[0] = all_feedback_d[0].concat("Total")
                            for (var i = 1; i < all_feedback_d.length; i++) {
                                if (all_feedback_d[i][0].toUpperCase() === "HAPPY") {
                                    for (j = 1; j < all_feedback_d[i].length; j++) {
                                        temp_happy = temp_happy + all_feedback_d[i][j]
                                    }
                                    all_feedback_d[i] = all_feedback_d[i].concat(temp_happy)
                                }
                                if (all_feedback_d[i][0].toUpperCase() === "NEUTRAL") {
                                    for (var j = 1; j < all_feedback_d[i].length; j++) {
                                        temp_neutral = temp_neutral + all_feedback_d[i][j]
                                    }
                                    all_feedback_d[i] = all_feedback_d[i].concat(temp_neutral)
                                }
                                if (all_feedback_d[i][0].toUpperCase() === "UNHAPPY") {
                                    for (j = 1; j < all_feedback_d[i].length; j++) {
                                        temp_unhappy = temp_unhappy + all_feedback_d[i][j]
                                    }
                                    all_feedback_d[i] = all_feedback_d[i].concat(temp_unhappy)
                                }
                            }

                            
                            all_userassist_categories_d.map(function (value, index, array) {

                            })


                            user_assist_topics.unshift(["Category", "New Topics"])
                            feedbackAll.push(feedback_happy, feedback_neutral, feedback_unhappy)
                           
                            queries.push([Pwd_S, Acc_S,akamai_s,null],
                                [Pwd_F, Acc_F,akamai_f,null],
                                [null, null,null, user_assist])

                            queries_d.push(["Transaction Type", "Successful", "UnSuccessful"],
                                ["Password Reset", Pwd_S, Pwd_F],
                                ["Account Unlock", Acc_S, Acc_F])
                            queries_ud.push(["Transaction Type", "Total Transactions"], ["User Assist", user_assist])
                            users.push([pwdUser, accUser,akamaiUser, assistUser])
                            users_d.push(["Password Reset", pwdUser], ["Account Unlock", accUser],
                                ["User Assist", assistUser]
                            )
                            


                            var pwdReason = []
                            res.data.result.map(system => {

                                Object.keys(system.failure_reason.Acc_F).map(function (key) {
                                    if (reasons_a.length == 0) {
                                        reasons_a.push([key, system.failure_reason.Acc_F[key]])
                                    }
                                    else {
                                        let flag = 0;
                                        for (var i = 0; i < reasons_a.length; i++) {
                                            if (reasons_a[i][0] === key) {
                                                reasons_a[i][1] += system.failure_reason.Acc_F[key]
                                                flag += 1
                                            }
                                        }
                                        if (flag == 0) {
                                            reasons_a.push([key, system.failure_reason.Acc_F[key]])
                                        }
                                    }

                                });

                                Object.keys(system.failure_reason.Pwd_F).map(function (key) {
                                    if (reasons_p.length == 0) {
                                        reasons_p.push([key, system.failure_reason.Pwd_F[key]])
                                    }
                                    else {
                                        let flag = 0;
                                        for (var i = 0; i < reasons_p.length; i++) {
                                            if (reasons_p[i][0] === key) {
                                                reasons_p[i][1] += system.failure_reason.Pwd_F[key]
                                                flag += 1
                                            }
                                        }
                                        if (flag == 0) {
                                            reasons_p.push([key, system.failure_reason.Pwd_F[key]])
                                        }
                                    }

                                });

                                Object.keys(system.feedback).map(function (key) {
                                    if (feedback_d.length == 0) {
                                        feedback_d.push([key, system.feedback[key]])
                                        feedback[0].push(key)
                                        feedback[1].push(system.feedback[key])
                                    }
                                    else {
                                        let flag = 0;
                                        for (var i = 0; i < feedback_d.length; i++) {
                                            if (feedback_d[i][0] === key) {
                                                feedback_d[i][1] += system.feedback[key]
                                                feedback[1][i] += system.feedback[key]
                                                flag += 1
                                            }
                                        }

                                        if (flag == 0) {
                                            feedback_d.push([key, system.feedback[key]])
                                            feedback[0].push(key)
                                            feedback[1].push(system.feedback[key])
                                        }
                                    }

                                });

                            })


                            feedback_d.unshift(["Feedback", "Count"])
                            reasons_a.unshift(["Reason", "Count"])
                            reasons_p.unshift(["Reason", "Count"])

                        } catch (error) {
                            console.log(error)
                            status = 404

                        }






                        return { user_assist_topics, feedback_comment, all_userassist_categories_d, all_new_users, all_users, all_feedback_d, transactions, reason_pwd_d, reason_acc_d, status, queries, users, queries_d, users_d, feedback, feedback_d, reasons_p, reasons_a, queries_ud, user_assist_sub_category, user_assist_messages,akamai_transactions_d,reasons_akamai };

                    }).catch(error => {
                        let status = 404;
                        return status;
                    })

            } catch (error) {
                console.log(error)
                let status = 404;
                return status;
            }

        }
    }
    else {
        var status = 200

        return axios.get('https://c8.avaamo.com/bots/analytics/' + bot_id + '/top_intents.json?from_date=' + from_date + '&to_date=' + to_date, {
            "headers": {
                "Content-Type": "application/json",
                "Access-Token": "5c9d4332f3924c908013d14d78d903a7"
            }
        }).then(val => {
            let top_intents = [];
            var intents = [[], []];//[["Password Reset","Account Unlock", "User Assist"]]
            let user_assist_intents = [];
            let user_assist_intent_count = [];

            if (val.status === 200) {
                val.data.top_intents.map(
                    intent => {
                        top_intents.push([intent.intent_name, intent.intent_count])
                    }
                );
            }
            top_intents.map(
                intent => {
                    intents[0].push(intent[0])
                    intents[1].push(intent[1])
                }
            )
            top_intents.unshift(["Transaction Type", "Count"])



            return axios.post("https://c8.avaamo.com/dashboard/bots_audience_lists/result_by_filters.json?per_page=10000",
                {
                    start_date: from_date,
                    end_date: to_date,
                    bot_ids: [bot_id],
                    utc_offset: 19800,
                    goal_node_keys: ["1.4"]
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Token": "5c9d4332f3924c908013d14d78d903a7"
                    }
                }
            )
                .then(response => {
                    var temp = [],
                        unique = [],
                        esbData = [];
                    response.data.entries.map(e => {
                        temp.push(e.user.display_name.toUpperCase());
                    });
                    temp.map(e => {
                        if (!unique.includes(e)) {
                            unique.push(e);
                        }
                    });

                    esbData.push(response.data.total_entries);
                    esbData.push(unique.length);



                    return axios.get('https://c8.avaamo.com/dashboard/bots/' + bot_id + '/feedbacks/user_feedbacks.json?from_date=' + from_date + '&to_date=' + to_date, {
                        "headers": {
                            "Content-Type": "application/json",
                            "Access-Token": "5c9d4332f3924c908013d14d78d903a7"
                        }
                    }).then(res => {
                        let feedback1 = [], feedback_d = [["Feedback", "Count"]], feedback = [["Positive Feedback", "Negative Feedback"]], positive = 0, negative = 0;
                        if (res.status === 200) {
                            feedback1 = res.data.user_feedbacks.map(
                                fb => {
                                    if (fb.feedback_type.toUpperCase() === "POSITIVE FEEDBACK") {
                                        positive = fb.count;
                                    }
                                    else {
                                        negative = fb.count;
                                    }
                                    return [fb.feedback_type, fb.count];
                                }
                            );
                        }
                        feedback.push([positive, negative])
                        feedback_d = feedback_d.concat(feedback1)

                        return {

                            esbData,
                            status,
                            feedback,
                            feedback_d

                        }

                    })



                })
        }).catch(function (error) {
                console.log(error);
                let status = 404;
                return status;

            });


    }
}


function getChartComponents(queries, users, feedback, bot_id, esb, data, changed) {
    if (!changed) {
        var sorted_feedback = [[],[],[]]
        console.log(feedback)
        try{
        if(feedback[0].length>0)
        for(var i =0;i<feedback[0].length;i++){
        if(feedback[0][i].toUpperCase()== "HAPPY"){
            sorted_feedback[0].push(feedback[1][i]);//"#00b300", "#ffff1a", "#ff0000"
            sorted_feedback[1].push(feedback[0][i]);
            sorted_feedback[2].push("#00b300");
        }
            if(feedback[0][i].toUpperCase()== "NEUTRAL"){
                sorted_feedback[0].push(feedback[1][i]);//"#00b300", "#ffff1a", "#ff0000"
                sorted_feedback[1].push(feedback[0][i]);
            sorted_feedback[2].push("#ffff1a");
        }
        if(feedback[0][i].toUpperCase()== "UNHAPPY"){
            sorted_feedback[0].push(feedback[1][i]);//"#00b300", "#ffff1a", "#ff0000"
            sorted_feedback[1].push(feedback[0][i]);
            sorted_feedback[2].push("#ff0000");
        }
        
        }
        }catch(error){
        console.log(error)
        }
        console.log(sorted_feedback)
        if (bot_id === parseInt(process.env.REACT_APP_SMART_SERVICE_DESK_ID)) {
            return (
                <Grid container spacing={3} className={styles.selectors}>
                    <Grid item xs={12} sm={12}>
                        <Paper className={styles.paper} elevation={4}>
                            <div className="App">
                                <Plot
                                    data={[
                                        {
                                            x: queries[0],
                                            y: queries[1],
                                            name: "Successful",
                                            type: "bar",
                                            marker: { color: " #86b300"}
                                        },
                                        {
                                            x: queries[0],
                                            y: queries[2],
                                            name: "UnSuccessful",
                                            type: "bar",
                                            marker: { color: " #ff1a1a" }
                                        },

                                        {
                                            x: queries[0],
                                            y: queries[3],
                                            name: "All",
                                            type: "bar",
                                            marker: { color: "#17BECF" }
                                        }
                                    ]}
                                    layout={{
                                        autosize: true,
                                        title: "User Queries",
                                        barmode: "group"
                                    }}
                                    useResizeHandler={true}
                                    style={{ width: "100%", height: "100%" }}
                                    config={{ displaylogo: false }}
                                    />
                            </div>
                        </Paper>
                    </Grid>



                    <Grid item xs={12} sm={6}>
                        <Paper className={styles.paper} elevation={10}>
                            <div className="App">
                                <Plot
                                    data={[
                                        {
                                            x: users[0],
                                            y: users[1],
                                            type: "bar",
                                            marker: { color: "#17BECF" }
                                        }
                                    ]}
                                    layout={{ autosize: true, title: "Users" }}
                                    useResizeHandler={true}
                                    style={{ width: "100%", height: "100%" }}
                                    config={{ displaylogo: false }}
                                    />
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Paper className={styles.paper} elevation={10}>
                            <div className="App">
                                <Plot
                                    data={[
                                        {
                                            values: sorted_feedback[0],
                                            labels: sorted_feedback[1],
                                            type: "pie",
                                            marker: { colors: sorted_feedback[2] }
                                        }
                                    ]}
                                    layout={{ autosize: true, title: "Feedback" }}
                                    useResizeHandler={true}
                                    style={{ width: "100%", height: "100%" }}
                                    config={{ displaylogo: false }}
                                    />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            );
        }
        else {


            return (
                <Grid container spacing={3} className={styles.selectors}>



                    <Grid item xs={12} sm={6}>
                        <Paper className={styles.paper} elevation={10}>
                            <div className="App">
                                <Plot
                                    data={[
                                        {
                                            x: ["Queries"],
                                            y: [esb[0]],
                                            type: "bar",
                                            marker: { color: "#17BECF" }
                                        }
                                    ]}
                                    layout={{ autosize: true, title: "Queries" }}
                                    useResizeHandler={true}
                                    style={{ width: "100%", height: "100%" }}
                                    config={{ displaylogo: false }}
                                    />
                            </div>
                        </Paper>
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <Paper className={styles.paper} elevation={10}>
                            <div className="App">
                                <Plot
                                    data={[
                                        {
                                            x: ["Users"],
                                            y: [esb[1]],
                                            type: "bar",
                                            marker: { color: "#17BECF" }
                                        }
                                    ]}
                                    layout={{ autosize: true, title: "Users" }}
                                    useResizeHandler={true}
                                    style={{ width: "100%", height: "100%" }}
                                    config={{ displaylogo: false }}
                                    />
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Paper className={styles.paper} elevation={10}>
                            <div className="App">
                                <Plot
                                    data={[
                                        {
                                            values: feedback[1],
                                            labels: feedback[0],
                                            type: "pie",
                                            marker: { colors: ["#00b300", "#ffff1a", "#ff0000"] }
                                        }
                                    ]}
                                    layout={{ autosize: true, title: "Feedback" }}
                                    useResizeHandler={true}
                                    style={{ width: "100%", height: "100%" }}
                                    config={{ displaylogo: false }}
                                    />
                            </div>
                        </Paper>
                    </Grid>


                </Grid>
            )


        }
    } else {
        return (
            <Grid>
            </Grid>
        )
    }


}


class Dashboard extends Component {

    callGetData = () => {
        this.setState({ dataReceived: false, selected: false, error: false })
       
        let start_date =
            new Date(this.state.start_date).getFullYear() +
            "/" +
            (new Date(this.state.start_date).getMonth() + 1) +
            "/" +
            new Date(this.state.start_date).getDate();
        let end_date =
            new Date(this.state.end_date).getFullYear() +
            "/" +
            (new Date(this.state.end_date).getMonth() + 1) +
            "/" +
            new Date(this.state.end_date).getDate();

        getDataForGraph(this.state.bot_id, start_date, end_date, this.state.kernel, this.state.allKernels, this.state.faqSystems, this.state.fls_a, this.state.fls_b, this.state.fls_c, this.state.fls_d,this.state.all_services_part_a,this.state.all_services_part_b,this.state.all_services_part_c,this.state.all_services_part_d).then(res => {
            console.log("response from getdata", res)
            if (res.status === 200 && res) {
                this.setState({
                    queries: res.queries,
                    users: res.users,
                    feedback: res.feedback,
                    //intents:res.intents,
                    feedback_d: res.feedback_d,
                    feedback_comment_system: res.feedback_comment_system,
                    queries_d: res.queries_d,
                    users_d: res.users_d,
                    reasons_a: res.reasons_a,
                    reasons_p: res.reasons_p,
                    //top_intents:res.top_intents,
                    queries_ud: res.queries_ud,
                    dataReceived: true,
                    esbData: res.esbData,
                    user_assist_messages: res.user_assist_messages,
                    user_assist_sub_category: res.user_assist_sub_category,
                    reason_pwd_d: res.reason_pwd_d,
                    reason_acc_d: res.reason_acc_d,
                    transactions: res.transactions,
                    all_feedback_d: res.all_feedback_d,
                    all_users: res.all_users,
                    all_userassist_categories_d: res.all_userassist_categories_d,
                    feedback_comment: res.feedback_comment,
                    user_assist_topics: res.user_assist_topics,
                    new_users_d: res.new_users_d,
                    all_new_users: res.all_new_users,
                    flsService: res.flsService,
                    akamai_transactions_d: res.akamai_transactions_d,
                    reasons_akamai: res.reasons_akamai,
                    reasons_akamai_redirect: res.reasons_akamai_redirect,
                    akamai_transactions_redirect_d : res.akamai_transactions_redirect_d,
                    windchill_transactions_d:res.windchill_transactions_d,
                    reasons_windchill:res.reasons_windchill,
                    m2o_ccrm_reactivate_transactions_d:res.m2o_ccrm_reactivate_transactions_d,
                    reasons_m2o_ccrm_reactivate:res.reasons_m2o_ccrm_reactivate
                })

            }
            else {
                this.setState({ error: true, dataReceived: true })
            }

        })
    }


    constructor(props) {
        super(props)
        console.log( process.env.REACT_APP_KERNELS.split(","))
        var ktest = process.env.REACT_APP_KERNELS.split(",")
        this.inputLabel = createRef();
        let dateObj = new Date()
        var d = dateObj;
        var start_date = new Date(d.setDate(d.getDate() - 7))
        this.state = {
            dataReceived: false,
            start_date: [start_date],
            end_date: [new Date()],
            bot_id: parseInt(process.env.REACT_APP_SMART_SERVICE_DESK_ID),
            anchorEl: null,
            labelWidth: 0,
            kernel: process.env.REACT_APP_KERNELS.split(",")[0],
            top_intents: [],
            feedback: [], labelWidth: 0,
            Time: [],
            queries: [],
            users: [],
            feedback: [],
            feedback_d: [],
            feedback_comment_system: [],
            intents: [],
            queries_d: [],
            users_d: [],
            reasons_a: [],
            reasons_p: [],
            queries_ud: [],
            esbData: [],
            user_assist_messages: [],
            user_assist_sub_category: [],
            endDate: false,
            error: false,
            allKernels: process.env.REACT_APP_KERNELS.split(","),
            faqSystems: process.env.REACT_APP_FAQ_SYSTEMS.split(","),
            fls_a: process.env.REACT_APP_FLS_SERVICES_PART_A.split(","),
            fls_b: process.env.REACT_APP_FLS_SERVICES_PART_B.split(","),
            fls_c: process.env.REACT_APP_FLS_SERVICES_PART_C.split(","),
            fls_d: process.env.REACT_APP_FLS_SERVICES_PART_D.split(","),
            all_services_part_a:process.env.REACT_APP_ALL_SERVICES_PART_A.split(","),
            all_services_part_b:process.env.REACT_APP_ALL_SERVICES_PART_B.split(","),
            all_services_part_c:process.env.REACT_APP_ALL_SERVICES_PART_C.split(","),
            all_services_part_d:process.env.REACT_APP_ALL_SERVICES_PART_D.split(","),
            flsService: [],
            selected: false,
            reason_pwd_d: [],
            reason_acc_d: [],
            transactions: [],
            all_feedback_d: [],
            all_users: [],
            all_userassist_categories_d: [],
            feedback_comment: [],
            user_assist_topics: [],
            new_users_d: [],
            all_new_users: [],
            akamai_transactions_d:[],
            reasons_akamai : [],
            akamai_transactions_redirect_d:[],
            reasons_akamai_redirect:[],
            reasons_windchill:[],
            windchill_transactions_d:[],
            reasons_windchill:[],
            m2o_ccrm_reactivate_transactions_d:[],
            reasons_m2o_ccrm_reactivate:[],
            start_date_d: new Date(start_date).getDate() + "/" + (new Date(start_date).getMonth() + 1) + "/" + new Date(start_date).getFullYear(),
            end_date_d: + new Date().getDate() + "/" + (new Date().getMonth() + 1) + "/" + new Date().getFullYear()


        }
        this.callGetData(this.state.date);
    }

   

    onStartDateChange = (dateChange) => {
        if (dateChange) {
            // console.log()
            let start_date_d = new Date(dateChange).getDate() + "/" + (new Date(dateChange).getMonth() + 1) + "/" + new Date(dateChange).getFullYear()

            this.setState({ start_date: dateChange, selected: true, start_date_d: start_date_d }, () => {
                console.log("Dates on Change => ", dateChange);
                console.log("state => ", this.state.date);
            })


        }
    }

    onEndDateChange = (dateChange) => {
        if (dateChange) {
            let end_date_d = new Date(dateChange).getDate() + "/" + (new Date(dateChange).getMonth() + 1) + "/" + new Date(dateChange).getFullYear()

            this.setState({ end_date: dateChange, selected: true, end_date_d: end_date_d }, () => {
                console.log("Dates on Change => ", dateChange);
                console.log("state => ", this.state.date);
            })


        }
    }



    handleBotChange = (event) => {

        this.setState({ bot_id: event.target.value, dataReceived: false }, () => {
            this.callGetData();
        });
    }

    handleClick = (event) => {
        this.setState({ "anchorEl": event.currentTarget }, () => {
            // console.log("setAnchorEl is", this.state.anchorEl)
        })
    };

    handleClose = () => {
        this.setState({ "anchorEl": null })
    };

    handleChange = (event) => {
        this.setState({ age: event.target.value })
    };

    handleSystemChange = (event) => {
        this.setState({
            kernel: event.target.value,
            selected: true
        })
    }

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname
        return (
            <React.Fragment>
                <CssBaseline />
                 <Topbar  alerts_access = {this.props.alerts_access} gob_access = {this.props.gob_access} akami_access={this.props.akami_access} firstName={this.props.firstName} lastName={this.props.lastName} codeID={this.props.codeID} emailID={this.props.emailID} dashboard_access={this.props.dashboard_access} admin_access={this.props.admin_access} esb_access={this.props.esb_access} />

                <div className={classes.root}><Grid container spacing={2} justify={"center"} >
                    <Grid item xs={12}>
                        <Typography variant="h4" color="secondary" >
                            <span className={classes.title} >Chatbot Performance Analytics</span>
                        </Typography>
                    </Grid>

                    <Grid item sm={3} className={classes.selectors}>
                        <FormControl variant="standard" className={classes.formControl}>
                            <InputLabel style={{ width: '200%', fontSize: '17px' }} id="demo-simple-select-outlined-label">Virtual Assistant</InputLabel>
                            <Select
                                disabled={!this.state.dataReceived}
                                style={{ fontSize: '17px' }}
                                ref={this.inputLabel}
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-filled-standard"
                                value={this.state.bot_id}
                                onChange={this.handleBotChange}
                                labelWidth={this.labelWidth}
                                inputProps={{
                                    name: 'bot_id',
                                    id: 'outlined-age-simple',
                                }}
                                >
                                <MenuItem value={parseInt(process.env.REACT_APP_SMART_SERVICE_DESK_ID)}>Smart Service Desk</MenuItem>
                                <MenuItem value={parseInt(process.env.REACT_APP_IT_ASSIST_BOT_ID)}>IT Analyst Buddy</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item sm={2} className={classes.selectors}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <KeyboardDatePicker autoOk
                                disabled={!this.state.dataReceived}
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Start Date"
                                disableFuture
                                minDate={new Date(parseInt("2020"), parseInt("02"), parseInt("03"))}
                                maxDate={this.state.end_date}
                                value={this.state.start_date}
                                onChange={this.onStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item sm={2} className={classes.selectors}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disabled={!this.state.dataReceived}
                                autoOk
                                disableToolbar
                                disableFuture
                                margin="normal"
                                variant="inline"
                                id="date-picker-dialog"
                                label="End Date"
                                format="MM/dd/yyyy"
                                value={this.state.end_date}
                                minDate={this.state.start_date}
                                onChange={this.onEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                />


                        </MuiPickersUtilsProvider>
                    </Grid>

                    {
                        this.state.bot_id === parseInt(process.env.REACT_APP_SMART_SERVICE_DESK_ID) ? (<Grid item sm={3} className={classes.selectors}>
                            <FormControl variant="standard" className={classes.formControl}>
                                <InputLabel style={{ width: '200%', fontSize: '17px' }} id="demo-simple-select-outlined-label">Service</InputLabel>
                                <Select
                                    disabled={!this.state.dataReceived}
                                    style={{ fontSize: '17px' }}
                                    ref={this.inputLabel}
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-filled-standard"
                                    value={this.state.kernel}
                                    onChange={this.handleSystemChange}
                                    >
                                    {this.state.allKernels.map(kernel => {
                                        return <MenuItem value={kernel}>{kernel}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>) : (<div></div>)
                    }
                    {this.state.dataReceived ? (<Grid className={classes.apply}>
                        <Button color="primary" variant="contained" onClick={this.callGetData}>
                            Apply
                        </Button>
                    </Grid>) : (<div></div>)}


                    {this.state.dataReceived && !this.state.selected && !this.state.error ? (
                        <Grid item sm={4} className={classes.downloadButton} style={{ paddingTop: '17px' }}>
                            <Button variant="outlined" >
                                <Download bot={(this.state.bot_id === parseInt(process.env.REACT_APP_SMART_SERVICE_DESK_ID)) ? "Athena" : "IT Analyst Buddy"}
                                    queries={this.state.queries_d}
                                    users={this.state.users_d}
                                    feedback={this.state.feedback_d}
                                    reasons_a={this.state.reasons_a}
                                    reasons_p={this.state.reasons_p}
                                    queries_ud={this.state.queries_ud}
                                    esb={this.state.esbData}
                                    user_assist_messages={this.state.user_assist_messages}
                                    user_assist_sub_category={this.state.user_assist_sub_category}
                                    service={this.state.kernel}
                                    faqSystems={this.state.faqSystems}
                                    reason_pwd_d={this.state.reason_pwd_d}
                                    reason_acc_d={this.state.reason_acc_d}
                                    transactions={this.state.transactions}
                                    all_feedback_d={this.state.all_feedback_d}
                                    all_users={this.state.all_users}
                                    all_userassist_categories_d={this.state.all_userassist_categories_d}
                                    feedback_comment={this.state.feedback_comment}
                                    feedback_comment_system={this.state.feedback_comment_system}
                                    user_assist_topics={this.state.user_assist_topics}
                                    new_users_d={this.state.new_users_d}
                                    all_new_users={this.state.all_new_users}
                                    startDate={this.state.start_date_d}
                                    endDate={this.state.end_date_d}
                                    kernel={this.state.kernel}
                                    flsService={this.state.flsService}
                                    akamai_transactions_d = {this.state.akamai_transactions_d}
                                    reasons_akamai = {this.state.reasons_akamai}
                                    akamai_transactions_redirect_d = {this.state.akamai_transactions_redirect_d}
                                    reasons_akamai_redirect = {this.state.reasons_akamai_redirect}
                                    windchill_transactions_d = {this.state.windchill_transactions_d}
                                    reasons_windchill = {this.state.reasons_windchill}
                                    m2o_ccrm_reactivate_transactions_d = {this.state.m2o_ccrm_reactivate_transactions_d}
                                    reasons_m2o_ccrm_reactivate = {this.state.reasons_m2o_ccrm_reactivate}
                                    />
                            </Button>
                        </Grid>) : (<div></div>)}



                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {
                                !this.state.selected && !this.state.error ?
                                    <span className={classes.subTitle}>Showing the data for {(this.state.bot_id === parseInt(process.env.REACT_APP_SMART_SERVICE_DESK_ID)) ? this.state.kernel : "ESB Buddy"}</span> :
                                    !this.state.error ? <Typography variant='body1' align='center'>Please click on apply to fetch data.</Typography> : <div> </div>
                            }
                        </Typography>
                    </Grid>

                    {
                        this.state.error ? <div>There was an error loading the data, Please try after some time.</div> : !this.state.dataReceived ?
                            <Grid item xs={1} sm={1}><CircularProgress /></Grid> :
                            <Grid item xs={10}>{getChartComponents(this.state.queries, this.state.users, this.state.feedback, this.state.bot_id, this.state.esbData, this.state.dataReceived, this.state.selected)}</Grid>
                    }

                </Grid>


                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(Dashboard));